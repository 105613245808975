import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ForgotPasswordCall } from 'services';
import { useCallbackWithLoading } from 'hooks';
import { useForm, Controller } from 'react-hook-form';
import { UserManagementWrapper } from '../UserManagementWrapper';
import { CardStyled } from '../UserManagementWrapper.styled';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'parameters';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BytegaButton, FormStyled, TextFieldStyled } from 'components';

export const ForgotPassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [forgot, forgotPending] = useCallbackWithLoading(ForgotPasswordCall);

    const validationSchema = Yup.object().shape({
        email: Yup.string().required(t('Email is required')).email(t('Email is invalid'))
    });

    const {
        control,
        handleSubmit,

        formState: { errors }
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            email: ''
        },
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: { email: string }) => {
        forgot({ email: data.email })
            .then(res => {
                toast.success('Request Sent');
                navigate(ROUTES.home);
            })
            .catch(err => {
                toast.error('Request Failed');
            });
    };

    return (
        <UserManagementWrapper>
            <FormStyled onSubmit={handleSubmit(onSubmit)}>
                <CardStyled elevation={0}>
                    <Grid container direction='column' justifyContent='flex-start' alignItems='center' gap={1} p={1}>
                        <Controller
                            name='email'
                            control={control}
                            render={({ field }) => (
                                <TextFieldStyled
                                    {...field}
                                    required
                                    id='email'
                                    label={`${t('Email')}`}
                                    error={errors.email ? true : false}
                                    helperText={errors.email?.message ? errors.email?.message : null}
                                />
                            )}
                        />
                        <BytegaButton
                            className={`primary`}
                            type='submit'
                            variant='contained'
                            disabled={forgotPending}
                            text={t('forgotbutton')}
                            fullWidth={true}
                        />

                        <BytegaButton
                            variant='outlined'
                            type='reset'
                            disabled={forgotPending}
                            onClick={() => navigate(ROUTES.contact)}
                            text={t('I need help')}
                            fullWidth={true}
                        />
                    </Grid>
                </CardStyled>
            </FormStyled>
        </UserManagementWrapper>
    );
};
