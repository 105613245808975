import { Grid, Typography } from '@mui/material';
import { PrivateWrapper } from 'components/ux/private-wrapper/PrivateWrapper';
import { useTranslation } from 'react-i18next';
import { AllAdminVehicleGrid } from './AllAdminVehicleGrid';

export function AdminVehicle() {
    const { t } = useTranslation();

    return (
        <>
            <PrivateWrapper
                title={t('adminVehicles.PageLabel')}
                buttonTitle={t('userVehicle.PublishVehicleDialog.DialogTitle')}
                buttonDisabled={false}>
                <Grid container direction='column'>
                    <Grid
                        container
                        item={true}
                        direction='column'
                        justifyContent='flex-start'
                        alignItems='start'
                        gap={1}
                        xs={4}
                        p={1}>
                        <Typography variant='h6' gutterBottom>
                            {t('userVehicles.Vehicles')}
                        </Typography>
                        <Grid container item={true} direction='column' sx={{ height: 600, width: '100%' }}>
                            <AllAdminVehicleGrid></AllAdminVehicleGrid>
                        </Grid>
                    </Grid>
                </Grid>
            </PrivateWrapper>
        </>
    );
}
