import React, { useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BytegaButton, DetailsDialog } from 'components';
import { DeleteAdminStorage, GetAdminSingleStorage, PatchAdminStorage } from 'services';
import { toast } from 'react-toastify';
import { IStorageInput, IStorageViewModel } from 'models';
import { UserContext } from 'context';
import { queryClient } from 'index';
import useConfirm from 'hooks/useConfirm';
import { useQuery } from 'react-query';
import StorageForm from 'components/storage/StorageForm';

export interface NotificationDialogProps {
    open: boolean;
    selected: number;
    onClose?: () => void;
    onOpen?: () => void;
}

export const StorageAdminEditDialog = (props: NotificationDialogProps) => {
    const { onClose, open, onOpen, selected } = props;
    const storageFormRef = useRef<any>(null);
    const { userId } = useContext(UserContext);
    const { t } = useTranslation();
    const [ConfirmationDialog, confirmEvent] = useConfirm(
        t('storageEditDialog.Confirm'),
        t('storageEditDialog.ConfirmationDelete')
    );

    const handleClose = () => {
        onClose && onClose();
    };

    const handleAccept = () => {
        storageFormRef.current.triggerSubmit();
    };

    const onSubmit = (data: IStorageInput) => {
        delete (data as any).user;
        PatchAdminStorage({ data, id: selected })
            .then(res => {
                toast.success(t('Save Successful'));
            })
            .catch(err => {
                toast.error(`${t('Login Failed')}: ${err.message}`);
            })
            .finally(() => {
                handleClose();
                queryClient.invalidateQueries(['user_storages', userId]);
                queryClient.invalidateQueries(['storage', selected]);
            });
    };

    const onDelete = async () => {
        const ans = await confirmEvent();
        if (ans) {
            DeleteAdminStorage({ id: selected })
                .then(res => {
                    toast.success(t('Delete Successful'));
                })
                .catch(err => {
                    toast.error(`${t('Delete Failed')}: ${err.message}`);
                })
                .finally(() => {
                    handleClose();
                    queryClient.invalidateQueries(['user_storages', userId]);
                });
        }
    };

    const storageQuery = useQuery(['admin_storage', selected], () => GetAdminSingleStorage({ id: selected }), {
        staleTime: 86400,
        enabled: !!selected && !!(selected + 1) && selected !== Infinity
    });

    return (
        <DetailsDialog
            open={open}
            onClose={onClose && handleClose}
            onOpen={onOpen}
            onAccept={handleAccept}
            buttonOKtitle={t('buttons.SaveButtonLabel')}
            title={'Edit Storage'}>
            <>
                <StorageForm storageQuery={storageQuery} ref={storageFormRef} onSubmit={onSubmit}></StorageForm>
                <BytegaButton variant='contained' onClick={onDelete} text={t('buttons.DeleteButtonLabel')} />
                <ConfirmationDialog />
            </>
        </DetailsDialog>
    );
};
