import { styled } from '@mui/material';
import { sizes } from 'parameters';

export const FooterContainer = styled('footer')(({ theme }) => ({
    h2: {
        opacity: '0.6'
    },
    p: {
        opacity: '0.6'
    }
}));

export const ContactContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.light
}));

export const IconsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '44px'
}));

export const QuestionsContainer = styled('div')(({ theme }) => ({
    maxWidth: '226px',
    p: {
        paddingBottom: '8px'
    },
    a: {
        color: theme.palette.primary.main
    }
}));

export const TextContainer = styled('section')(({ theme }) => ({
    maxWidth: '906px',
    padding: '32px 16px',
    margin: '0 auto',
    display: 'flex',
    gap: '32px',
    justifyContent: ' space-between',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    order: '3 1 2',
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        textAlign: 'start',
        padding: '32px'
    }
}));
interface TextItemProps {
    order: number;
}

export const TextItem = styled('div', {
    shouldForwardProp: prop => prop !== 'success'
})<TextItemProps>(({ order, theme }) => ({
    order: order,
    h2: {
        paddingBottom: '24px'
    },
    [theme.breakpoints.up('sm')]: {
        order: 'unset'
    }
}));

export const LegalContainer = styled('section')(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
    textAlign: 'center',
    padding: '16px 0 24px'
}));
