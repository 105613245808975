import { Grid, Typography } from '@mui/material';
import { PrivateWrapper } from 'components/ux/private-wrapper/PrivateWrapper';
import { t } from 'i18next';
import React from 'react';
import { TaskGrid } from '../tasks/TaskGrid';

type Props = {};

export function Dashboard({}: Props) {
    return (
        <>
            <PrivateWrapper title={t('dashboard.PageLabel')}>
                <Grid container direction='row' gap={2}>
                    <Grid item={true} container direction='row'>
                        <Grid
                            item={true}
                            direction='column'
                            justifyContent='flex-start'
                            alignItems='start'
                            gap={1}
                            xs={6}
                            p={1}>
                            <Typography variant='h6' gutterBottom>
                                {t('dashboard.Transport')}
                            </Typography>
                            <Grid container item={true} direction='column' sx={{ height: 600, width: '100%' }}></Grid>
                        </Grid>
                        <Grid
                            item={true}
                            direction='column'
                            justifyContent='flex-start'
                            alignItems='start'
                            gap={1}
                            xs={6}
                            p={1}>
                            <Typography variant='h6' gutterBottom>
                                {t('dashboard.Shipping')}
                            </Typography>
                            <Grid container item={true} direction='column' sx={{ height: 600, width: '100%' }}>
                                <TaskGrid
                                    task_state={'SUBMITTER'}
                                    task_type={'TRANSPORTATION'}
                                    filter={'PUBLISHED'}></TaskGrid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true} container direction='row'>
                        <Grid
                            item={true}
                            direction='column'
                            justifyContent='flex-start'
                            alignItems='start'
                            gap={1}
                            xs={6}
                            p={1}>
                            <Typography variant='h6' gutterBottom>
                                {t('dashboard.Transport')}
                            </Typography>
                            <Grid container item={true} direction='column' sx={{ height: 600, width: '100%' }}>
                                <TaskGrid
                                    task_state={'SUBMITTER'}
                                    task_type={'TRANSPORTATION'}
                                    filter={'ACCEPTED'}></TaskGrid>
                            </Grid>
                        </Grid>
                        <Grid
                            item={true}
                            direction='column'
                            justifyContent='flex-start'
                            alignItems='start'
                            gap={1}
                            xs={6}
                            p={1}>
                            <Typography variant='h6' gutterBottom>
                                {t('dashboard.Shipping')}
                            </Typography>
                            <Grid container item={true} direction='column' sx={{ height: 600, width: '100%' }}>
                                <TaskGrid
                                    task_state={'SUBMITTER'}
                                    task_type={'TRANSPORTATION'}
                                    filter={'PUBLISHED'}></TaskGrid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PrivateWrapper>
        </>
    );
}
