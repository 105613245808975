import { useContext, useMemo, useRef, useState } from 'react';

import { DeleteUser, EditUser, GetUser } from 'services';
import { useCallbackWithLoading } from 'hooks';
import { IService, IUserProfile } from 'models';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { AuthorizationContext } from 'context';
import { queryClient } from 'index';

import { UserProfileForm } from './UserProfileForm';
import { PrivateWrapper } from 'components/ux/private-wrapper/PrivateWrapper';
import useConfirm from 'hooks/useConfirm';
import { BytegaButton } from 'components/ux/basic-components/Buttons';
import { SetPassword } from './SetPassword';
import { Grid } from '@mui/material';

type FormValues = {
    user: IUserProfile;
    services: IService[];
};

export const UserProfile = () => {
    const { t } = useTranslation();
    const [passwordReset, setpasswordReset] = useState<boolean>(false);
    const userFormRef = useRef<any>(null);
    const [updateProfile, updatePending] = useCallbackWithLoading(EditUser);

    const [ConfirmationDeleteDialog, confirmDeleteEvent] = useConfirm(
        t('userEditDialog.Confirm'),
        t('userEditDialog.ConfirmationDelete')
    );

    const { getUserProfile, logoutUser } = useContext(AuthorizationContext);

    const userId: number = useMemo(() => {
        return getUserProfile?.id ?? null;
    }, [getUserProfile]);

    const userQuery = useQuery(['userData', userId], () => GetUser({ id: userId }), {
        staleTime: 86400,
        enabled: typeof userId === 'number'
    });

    const onSubmit = (data: FormValues) => {
        let profile = data.user;
        profile.service = data.services.filter(el => el.active).map(el => el.id);
        console.log('profile userId', userId);
        updateProfile({ id: userId, data: profile })
            .then(res => {
                toast.success(t('Save Successful'));
                queryClient.invalidateQueries(['userData', userId]);
            })
            .catch(err => {
                console.log('err', err);
                toast.error(t('Save Failed'));
            });
    };

    const onDelete = async () => {
        const ans = await confirmDeleteEvent();
        if (ans) {
            DeleteUser({ id: userId })
                .then(res => {
                    toast.success(t('Delete Successful'));
                    logoutUser();
                })
                .catch(err => {
                    toast.error(`${t('Delete Failed')}: ${err.message}`);
                })
                .finally(() => {});
        }
    };

    return (
        <PrivateWrapper
            title={t('userProfile.PageLabel')}
            buttonTitle={t('buttons.SaveButtonLabel')}
            action={() => userFormRef.current.triggerSubmit()}
            buttonDisabled={updatePending}>
            <UserProfileForm
                userQuery={userQuery}
                ref={userFormRef}
                onSubmit={onSubmit}
                updatePending={updatePending}></UserProfileForm>
            <Grid container direction='column'>
                <Grid
                    container
                    item={true}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='space-between'
                    gap={1}
                    xs={4}
                    p={1}>
                    <BytegaButton
                        variant='contained'
                        onClick={() => setpasswordReset(true)}
                        text={t('buttons.ResetPasswordButtonLabel')}
                    />
                    <BytegaButton variant='outlined' onClick={onDelete} text={t('buttons.DeleteProfileButtonLabel')} />
                </Grid>
            </Grid>

            <ConfirmationDeleteDialog />
            <SetPassword open={passwordReset} onClose={() => setpasswordReset(false)} />
        </PrivateWrapper>
    );
};
