import React, { forwardRef, useMemo, useImperativeHandle, useRef, useCallback, useContext, useEffect } from 'react';
import {
    Autocomplete,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    TextField,
    Tooltip
} from '@mui/material';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { FormSimpleStyled, SelectProfileStyled, TextFieldProfileStyled } from 'components';
import { ITaskType, IMarketTask } from 'models';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { GetSingleTask, GetUserStorages, GetUserVehicles } from 'services';
import InfoIcon from '@mui/icons-material/Info';
import { GlobalContext, UserContext } from 'context';
import { ValidationError } from 'yup';
import { removeNull } from 'utils';
// import useConfirm from 'hooks/useConfirm';
// import { queryClient } from 'index';
// import { toast } from 'react-toastify';

type Props = {
    onSubmit: (data: any) => void;
    selected?: string;
    url?: string;
    preview?: boolean;
};

const MarketForm = forwardRef((props: Props, ref) => {
    const formRef = useRef<any>(null);
    const { onSubmit, selected, url, preview } = props;
    const { t } = useTranslation();
    const { userId } = useContext(UserContext)!;
    const { taskTypeListQuery, cityListQuery } = useContext(GlobalContext);

    const transportationValidationSchema = Yup.object().shape({
        task_type: Yup.number().required(t('taskForm.task_type_required')),
        location_from: Yup.string().required(t('taskForm.city_required')),
        vehicle: Yup.string().required(t('taskForm.vehicle_required')),
        date_from: Yup.date().required(t('taskForm.date_from_required_pickup')),
        description: Yup.string().required(t('taskForm.description_required')),
        price: Yup.string().required(t('taskForm.price_required'))
    });

    const storageValidationSchema = Yup.object().shape({
        task_type: Yup.number().required(t('taskForm.task_type_required')),
        date_from: Yup.date().required(t('taskForm.date_from_required')),
        date_to: Yup.date().required(t('taskForm.date_to_required')),
        description: Yup.string().required(t('taskForm.description_required')),
        storage: Yup.string().required(t('taskForm.storage_required')),
        price: Yup.string().required(t('taskForm.price_required'))
    });

    const shippingValidationSchema = Yup.object().shape({
        task_type: Yup.number().required(t('taskForm.task_type_required')),
        location_from: Yup.string().required(t('taskForm.location_from_required')),
        location_to: Yup.string().required(t('taskForm.location_to_required')),
        cargo_type: Yup.string().required(t('taskForm.cargo_type_required')),
        date_from: Yup.date().required(t('taskForm.date_from_required_pickup')),
        date_to: Yup.date().required(t('taskForm.date_from_required_delivery')),
        description: Yup.string().required(t('taskForm.description_required')),
        price: Yup.string().required(t('taskForm.price_required'))
    });

    const taskQuery = useQuery(['task', selected], () => GetSingleTask({ url }), {
        staleTime: 86400,
        enabled: !!selected && !!(selected + 1)
    });

    const userStoragesQuery = useQuery(['user_storages', userId], () => GetUserStorages(), {
        staleTime: 10000,
        enabled: !!userId && !!!preview
    });

    const userVehiclesQuery = useQuery(['user_vehicles', userId], () => GetUserVehicles(), {
        staleTime: 10000,
        enabled: !!userId && !!!preview
    });

    const vehicleToUse = useMemo(
        () => (preview ? [taskQuery.data?.vehicle] : userVehiclesQuery?.data),
        [preview, taskQuery.data?.vehicle, userVehiclesQuery?.data]
    );

    const storageToUse = useMemo(
        () => (preview ? [taskQuery.data?.storage] : userStoragesQuery?.data),
        [preview, taskQuery.data?.storage, userStoragesQuery?.data]
    );

    const loading = useMemo(
        () =>
            !cityListQuery.isLoading &&
            !userStoragesQuery.isLoading &&
            !taskQuery.isLoading &&
            !userVehiclesQuery.isLoading &&
            !taskTypeListQuery.isLoading,
        [
            cityListQuery.isLoading,
            userStoragesQuery.isLoading,

            taskQuery.isLoading,
            userVehiclesQuery.isLoading,

            taskTypeListQuery.isLoading
        ]
    );

    useImperativeHandle(ref, () => ({
        triggerSubmit: () => {
            handleSubmit(onSubmit)();
        }
    }));

    useEffect(() => {
        if (!!taskQuery.data?.id) {
            const cleanedStorage = removeNull(taskQuery.data as any);
            Object.keys(cleanedStorage).forEach((key: string) => {
                const formKey = `${key}` as any;
                setValue(formKey, cleanedStorage[key], { shouldDirty: false });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskQuery.data]);

    const useYupValidationResolver = (shemas: any, t: any) =>
        useCallback(
            async (data: any) => {
                const { transportationValidationSchema, shippingValidationSchema, storageValidationSchema } = shemas;

                const task_code = taskTypeListQuery.data
                    ?.filter((el: ITaskType) => Number(el.id) === Number(task_type))
                    .map(el => el.code)[0];

                const getShema = (task_code?: string) => {
                    // {id: 1, name: 'Transportation', code: 'transportation', active: true}
                    // {id: 2, name: 'Storage', code: 'storage', active: true}
                    // {id: 3, name: 'Shipping', code: 'shipping', active: true}
                    switch (task_code) {
                        case 'shipping':
                            return shippingValidationSchema;
                        case 'transportation':
                            return transportationValidationSchema;
                        case 'storage':
                            return storageValidationSchema;
                        default:
                            return transportationValidationSchema;
                    }
                };

                try {
                    const values = await getShema(task_code).validate(data, {
                        abortEarly: false
                    });

                    return {
                        values,
                        errors: {}
                    };
                } catch (errors: any) {
                    if (errors instanceof ValidationError)
                        return {
                            values: {},
                            errors: errors.inner.reduce(
                                (allErrors, currentError) => ({
                                    ...allErrors,
                                    [currentError.path!]: {
                                        type: currentError.type ?? 'validation',
                                        message: currentError.message
                                    }
                                }),
                                {}
                            ) as any
                        };
                    throw new Error(t('taskForm.validate_error'));
                }
            },
            [shemas, t, taskTypeListQuery?.data]
        );

    const resolver = useYupValidationResolver(
        {
            transportationValidationSchema,
            storageValidationSchema,
            shippingValidationSchema
        },
        t
    );

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            task_type: undefined,
            classification: undefined,
            city: undefined,
            location_from: undefined,
            location_to: undefined,
            distance: undefined,
            cargo_type: undefined,
            date_from: undefined,
            date_to: undefined,
            vehicle: undefined,
            storage: undefined,
            description: '',
            price: undefined
        } as IMarketTask,
        resolver
    });

    const errors_typefree = errors as any;

    const task_type: string = watch('task_type');

    const task_code = useMemo(
        () =>
            taskTypeListQuery.data
                ?.filter((el: ITaskType) => Number(el.id) === Number(task_type))
                .map(el => el.code)[0],
        [task_type, taskTypeListQuery?.data]
    );

    console.log('task_code', task_code);

    // const [ ConfirmationDialog, confirmEvent ] = useConfirm(
    // 	t('Are you sure?'),
    // 	t('Are you sure you want to execute action?')
    // );

    // const onReactivate = async () => {
    // 	const ans = await confirmEvent();
    // 	if (ans && selected && url) {
    // 		ReactivateTask({ id: selected, url })
    // 			.then(res => {
    // 				toast.success(t('Activation Successful'));
    // 			})
    // 			.catch(err => {
    // 				toast.error(`${t('Activation Failed')}: ${err.message}`);
    // 			})
    // 			.finally(() => {
    // 				queryClient.invalidateQueries({ queryKey: ['tasks'] });
    // 			});
    // 	}
    // };

    return loading ? (
        <FormSimpleStyled ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction='column' justifyContent='flex-start' alignItems='start' gap={1} py={1}>
                <Controller
                    name='task_type'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControl fullWidth>
                            <InputLabel id={'task_type'}>
                                {t('marketPage.OfferDetailsDialog.TaskType') + '*'}
                            </InputLabel>
                            <SelectProfileStyled
                                onChange={onChange}
                                value={value || ''}
                                variant='outlined'
                                defaultValue={''}
                                error={errors_typefree?.task_type ? true : false}
                                label={t('taskDetails.task_type') + '*'}
                                inputProps={{
                                    readOnly: selected
                                }}>
                                {taskTypeListQuery.data !== undefined ? (
                                    taskTypeListQuery.data?.map((task: ITaskType) => (
                                        <MenuItem key={task.id} value={Number(task.id)}>
                                            {t(task.name.toUpperCase())}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value={0}>{t('dropdown.NoData')}</MenuItem>
                                )}
                            </SelectProfileStyled>
                            <FormHelperText error={true}>
                                {errors_typefree?.task_type?.message ? errors_typefree?.task_type?.message : ''}
                            </FormHelperText>
                        </FormControl>
                    )}
                />

                {task_code === 'transportation' && (
                    <Controller
                        name='location_from'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormControl fullWidth>
                                <Autocomplete
                                    onChange={(event: any, newValue: any | null) => {
                                        onChange(newValue.value);
                                    }}
                                    options={
                                        cityListQuery.data?.map(el => {
                                            return { label: el.name, value: el.id };
                                        }) || []
                                    }
                                    value={
                                        value
                                            ? cityListQuery.data
                                                  ?.filter(el => el.id === value)
                                                  .map(el => {
                                                      return { label: el.name, value: el.id };
                                                  })[0]
                                            : null
                                    }
                                    sx={{ width: 300 }}
                                    readOnly={preview}
                                    renderInput={(params: any) => (
                                        <TextField {...params} label={t('taskDetails.city') + '*'} variant='outlined' />
                                    )}
                                />
                                <FormHelperText error={true}>
                                    {errors_typefree.location_from?.message
                                        ? errors_typefree.location_from?.message
                                        : ''}
                                </FormHelperText>
                            </FormControl>
                        )}
                    />
                )}
                {task_code === 'shipping' && (
                    <>
                        <Controller
                            name='location_from'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth>
                                    <Autocomplete
                                        onChange={(event: any, newValue: any | null) => {
                                            onChange(newValue.value);
                                        }}
                                        options={
                                            cityListQuery.data?.map(el => {
                                                return { label: el.name, value: el.id };
                                            }) || []
                                        }
                                        sx={{ width: 300 }}
                                        readOnly={preview}
                                        value={
                                            value &&
                                            cityListQuery.data
                                                ?.filter(el => el.id === value)
                                                .map(el => {
                                                    return { label: el.name, value: el.id };
                                                })[0]
                                        }
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                label={t('taskDetails.city') + '*'}
                                                variant='outlined'
                                            />
                                        )}
                                    />
                                    <FormHelperText error={true}>
                                        {errors_typefree.location_from?.message
                                            ? errors_typefree.location_from?.message
                                            : ''}
                                    </FormHelperText>
                                </FormControl>
                            )}
                        />

                        <Controller
                            name='location_to'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth>
                                    <Autocomplete
                                        onChange={(event: any, newValue: any | null) => {
                                            onChange(newValue.value);
                                        }}
                                        options={
                                            cityListQuery.data?.map(el => {
                                                return { label: el.name, value: el.id };
                                            }) || []
                                        }
                                        sx={{ width: 300 }}
                                        value={
                                            value &&
                                            cityListQuery.data
                                                ?.filter(el => el.id === value)
                                                .map(el => {
                                                    return { label: el.name, value: el.id };
                                                })[0]
                                        }
                                        readOnly={preview}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                label={t('marketPage.OfferDetailsDialog.LocationToDelivery') + '*'}
                                                variant='outlined'
                                            />
                                        )}
                                    />
                                    <FormHelperText error={true}>
                                        {errors_typefree.location_to?.message
                                            ? errors_typefree.location_to?.message
                                            : ''}
                                    </FormHelperText>
                                </FormControl>
                            )}
                        />
                    </>
                )}
                {task_code === 'transportation' && (
                    <Controller
                        name='date_from'
                        control={control}
                        render={({ field }) => (
                            <TextFieldProfileStyled
                                {...field}
                                required
                                type='date'
                                InputLabelProps={{ shrink: true }}
                                id='date_from'
                                label={`${t('marketPage.OfferDetailsDialog.DateFromPickup')}`}
                                error={errors_typefree.date_from ? true : false}
                                helperText={
                                    errors_typefree.date_from?.message ? errors_typefree.date_from?.message : null
                                }
                                inputProps={{
                                    readOnly: preview,
                                    min: new Date().toISOString().slice(0, 10)
                                }}
                            />
                        )}
                    />
                )}

                {task_code === 'storage' && (
                    <>
                        <Controller
                            name='date_from'
                            control={control}
                            render={({ field }) => (
                                <TextFieldProfileStyled
                                    {...field}
                                    required
                                    type='date'
                                    InputLabelProps={{ shrink: true }}
                                    id='date_from'
                                    label={`${t('marketPage.OfferDetailsDialog.DateFrom')}`}
                                    error={errors_typefree.date_from ? true : false}
                                    helperText={
                                        errors_typefree.date_from?.message ? errors_typefree.date_from?.message : null
                                    }
                                    inputProps={{
                                        readOnly: preview,
                                        min: new Date().toISOString().slice(0, 10)
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name='date_to'
                            control={control}
                            render={({ field }) => (
                                <TextFieldProfileStyled
                                    {...field}
                                    type='date'
                                    InputLabelProps={{ shrink: true }}
                                    required
                                    id='date_to'
                                    label={`${t('marketPage.OfferDetailsDialog.DateTo')}`}
                                    error={errors_typefree.date_to ? true : false}
                                    helperText={
                                        errors_typefree.date_to?.message ? errors_typefree.date_to?.message : null
                                    }
                                    inputProps={{
                                        readOnly: preview,
                                        min: new Date().toISOString().slice(0, 10)
                                    }}
                                />
                            )}
                        />
                    </>
                )}

                {task_code === 'shipping' && (
                    <>
                        <Controller
                            name='date_from'
                            control={control}
                            render={({ field }) => (
                                <TextFieldProfileStyled
                                    {...field}
                                    required
                                    type='date'
                                    InputLabelProps={{ shrink: true }}
                                    id='date_from'
                                    label={`${t('marketPage.OfferDetailsDialog.DateFromPickup')}`}
                                    error={errors_typefree.date_from ? true : false}
                                    helperText={
                                        errors_typefree.date_from?.message ? errors_typefree.date_from?.message : null
                                    }
                                    inputProps={{
                                        readOnly: preview,
                                        min: new Date().toISOString().slice(0, 10)
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name='date_to'
                            control={control}
                            render={({ field }) => (
                                <TextFieldProfileStyled
                                    {...field}
                                    type='date'
                                    InputLabelProps={{ shrink: true }}
                                    required
                                    id='date_to'
                                    label={`${t('marketPage.OfferDetailsDialog.DateToDelivery')}`}
                                    error={errors_typefree.date_to ? true : false}
                                    helperText={
                                        errors_typefree.date_to?.message ? errors_typefree.date_to?.message : null
                                    }
                                    inputProps={{
                                        readOnly: preview,
                                        min: new Date().toISOString().slice(0, 10)
                                    }}
                                />
                            )}
                        />
                    </>
                )}

                {/* <Controller
					name="distance"
					control={control}
					render={({ field }) => (
						<TextFieldProfileStyled
							{...field}
							required
							type="number"
							id="distance"
							label={`${t('marketPage.OfferDetailsDialog.distance')}`}
							error={errors_typefree.distance ? true : false}
							helperText={errors_typefree.distance?.message ? errors_typefree.distance?.message : null}
						/>
					)}
				/> */}
                {task_code === 'storage' && (
                    <Controller
                        name='storage'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormControl fullWidth>
                                <InputLabel id={'storage'}>
                                    {t('marketPage.OfferDetailsDialog.Storage') + '*'}
                                </InputLabel>
                                <SelectProfileStyled
                                    onChange={onChange}
                                    value={value || ''}
                                    variant='outlined'
                                    defaultValue={''}
                                    error={errors_typefree?.storage ? true : false}
                                    label={t('taskDetails.Storage') + '*'}
                                    inputProps={{
                                        readOnly: preview
                                    }}>
                                    {storageToUse !== undefined ? (
                                        storageToUse?.map(storage => (
                                            <MenuItem key={storage!.id} value={storage!.id}>
                                                {storage!.classification} ({storage!.dimensions})
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value={0}>{t('dropdown.NoData')}</MenuItem>
                                    )}
                                </SelectProfileStyled>
                                <FormHelperText error={true}>
                                    {errors_typefree.storage?.message ? errors_typefree.storage?.message : ''}
                                </FormHelperText>
                            </FormControl>
                        )}
                    />
                )}
                {task_code === 'shipping' && (
                    <Controller
                        name='cargo_type'
                        control={control}
                        render={({ field }) => (
                            <TextFieldProfileStyled
                                {...field}
                                required
                                id='cargo_type'
                                label={`${t('marketPage.OfferDetailsDialog.CargoTypeLabel')}`}
                                error={errors_typefree?.cargo_type ? true : false}
                                helperText={
                                    errors_typefree?.cargo_type?.message ? errors_typefree?.cargo_type?.message : null
                                }
                                autoComplete='cargo_type'
                                inputProps={{
                                    readOnly: preview,
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip title={t('marketPage.OfferDetailsDialog.CargoTypeLabel')}>
                                                <InfoIcon />
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                )}
                {task_code === 'transportation' && (
                    <Controller
                        name='vehicle'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormControl fullWidth>
                                <InputLabel id={'vehicle'}>
                                    {t('marketPage.OfferDetailsDialog.VehicleLabel') + '*'}
                                </InputLabel>
                                <SelectProfileStyled
                                    onChange={onChange}
                                    value={value || ''}
                                    variant='outlined'
                                    defaultValue={''}
                                    error={errors_typefree.vehicle ? true : false}
                                    label={t('marketPage.OfferDetailsDialog.VehicleLabel') + '*'}
                                    inputProps={{
                                        readOnly: preview
                                    }}>
                                    {vehicleToUse !== undefined ? (
                                        vehicleToUse?.map(vehicle => (
                                            <MenuItem key={vehicle!.id} value={vehicle!.id}>
                                                {`${vehicle!.vehicle_model} - ${vehicle!.vin}`}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value={0}>{t('dropdown.NoData')}</MenuItem>
                                    )}
                                </SelectProfileStyled>
                                <FormHelperText error={true}>
                                    {errors_typefree.vehicle?.message ? errors_typefree.vehicle?.message : ''}
                                </FormHelperText>
                            </FormControl>
                        )}
                    />
                )}
                {/* <Controller
					name="offer_expiration_date"
					control={control}
					render={({ field }) => (
						<TextFieldProfileStyled
							{...field}
							required
							type="date"
							InputLabelProps={{ shrink: true }}
							id="offer_expiration_date"
							label={`${t('marketPage.OfferDetailsDialog.offer_expiration_date')}`}
							error={errors_typefree.offer_expiration_date ? true : false}
							helperText={
								errors_typefree.offer_expiration_date?.message ? errors_typefree.offer_expiration_date?.message : null
							}
						/>
					)}
				/> */}
                {task_code && (
                    <>
                        <Controller
                            name='description'
                            control={control}
                            render={({ field }) => (
                                <TextFieldProfileStyled
                                    {...field}
                                    required
                                    rows={4}
                                    id='description'
                                    label={`${t('marketPage.OfferDetailsDialog.Description')}`}
                                    error={errors_typefree.description ? true : false}
                                    helperText={
                                        errors_typefree.description?.message
                                            ? errors_typefree.description?.message
                                            : null
                                    }
                                    inputProps={{
                                        readOnly: preview
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name='price'
                            control={control}
                            render={({ field }) => (
                                <TextFieldProfileStyled
                                    {...field}
                                    required
                                    type='number'
                                    id='price'
                                    label={`${t('marketPage.OfferDetailsDialog.Value')}`}
                                    error={errors_typefree.price ? true : false}
                                    helperText={errors_typefree.price?.message ? errors_typefree.price?.message : null}
                                    inputProps={{
                                        readOnly: preview
                                    }}
                                />
                            )}
                        />
                    </>
                )}
                {/* {selected && taskQuery?.data?.task_status === 5 && (
					<BytegaButton variant="contained" onClick={onReactivate} text={t('buttons.ActivateButtonLabel')} />
				)} */}
            </Grid>
        </FormSimpleStyled>
    ) : (
        <CircularProgress color='inherit' />
    );
});

export default MarketForm;
