import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Grid, Tooltip } from '@mui/material';

type Props = {
    title: string;
    imageUrl: string;
    imageTitle: string;
    description: string;
};

export function HomeServiceCard(props: Props) {
    const { title, imageUrl, description, imageTitle } = props;
    return (
        <Tooltip title={description}>
            <Card sx={{ maxWidth: 345, minWidth: 300 }}>
                <CardMedia sx={{ height: 140 }} image={imageUrl} title={imageTitle} />

                <CardContent>
                    <Grid container direction='column' alignItems='center'>
                        <Grid item>
                            <Typography gutterBottom variant='h5' component='div'>
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Tooltip>
    );
}
