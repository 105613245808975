import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { List, ListItem, ListItemIcon, Menu, MenuItem, ListItemText, useTheme, Grid } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonIcon from '@mui/icons-material/Person';
import Person2OutlinedIcon from '@mui/icons-material/PersonOutlined';
import { ROUTES } from 'parameters';
import { AuthorizationContext, UserProvider } from 'context';
import { DrawerStyled, MainView } from './Skeleton.style';
import useNavigation from 'hooks/useNavigation';
import { SetPassword } from 'components';

export const Skeleton = () => {
    const { t } = useTranslation();

    const { logoutUser, getUserInfo } = useContext(AuthorizationContext);

    const location = useLocation();
    const navigate = useNavigate();

    // const [languageAnchorEl, setLanguageAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openSideNav, setOpenSideNav] = React.useState(true);

    const nav = useNavigation(location.pathname.includes('app') ? 'app' : 'admin');

    const theme = useTheme();

    const classes = {
        root: {
            display: 'flex'
        },
        appBar: {
            '&.MuiAppBar-root': {
                overflow: 'hidden',
                backgroundColor: theme.palette.primary.light,
                zIndex: theme.zIndex.drawer + 1,
                color: 'white',
                boxShadow: 'unset'
            }
        },
        drawerContainer: {
            overflow: 'hidden',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'relative',
            color: 'white!important',
            backgroundColor: theme.palette.primary.light
        },
        content: {
            flexGrow: 1,
            transition: 'all 150ms ease-in-out',
            height: 'calc(100vh - 64px)'
        },
        smallSideNav: {
            width: '50px'
        },
        list: {
            backgroundColor: theme.palette.primary.light
        }
    };

    // const handleLanguageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setLanguageAnchorEl(event.currentTarget);
    // };

    // const handleLanguageClose = () => {
    //     setLanguageAnchorEl(null);
    // };

    const [settingsAnchorEl, setSettingsAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setSettingsAnchorEl(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setSettingsAnchorEl(null);
    };

    // const changeLanguage = (lng: string) => {
    //     localStorage.setItem('i18nextLng', lng);
    //     i18n.changeLanguage(lng);
    //     handleLanguageClose();
    // };

    const setPage = (route: string) => {
        navigate(`${route}`);
    };

    return (
        <Grid sx={classes.root}>
            <DrawerStyled variant='permanent' open={openSideNav}>
                <Grid sx={classes.drawerContainer}>
                    <div>
                        <List>
                            <>
                                {nav.map(navItem => (
                                    <ListItem
                                        sx={{
                                            height: '56px',
                                            '&.Mui-selected': {
                                                backgroundColor: theme.palette.secondary.light,
                                                span: {
                                                    color: theme.palette.primary.light
                                                },
                                                '&:hover *': {
                                                    color: theme.palette.secondary.light
                                                }
                                            }
                                        }}
                                        button
                                        selected={navItem.selected}
                                        key={navItem.text}
                                        onClick={() => setPage(navItem.route)}>
                                        <ListItemIcon>{navItem.icon}</ListItemIcon>
                                        <ListItemText
                                            sx={{ span: { color: theme.palette.secondary.light }, marginLeft: '-18px' }}
                                            primary={t(navItem.text)}
                                        />
                                    </ListItem>
                                ))}
                            </>
                        </List>
                    </div>
                    <div>
                        <List sx={classes.list}>
                            <ListItem
                                sx={{
                                    height: '56px',
                                    '&.Mui-selected': {
                                        backgroundColor: theme.palette.secondary.light,
                                        span: {
                                            color: theme.palette.primary.light
                                        },
                                        '&:hover *': {
                                            color: theme.palette.secondary.light
                                        }
                                    }
                                }}
                                button
                                selected={location.pathname.includes(ROUTES.userProfile)}
                                onClick={(e: any) => handleSettingsClick(e)}>
                                <ListItemIcon>
                                    {location.pathname.includes(ROUTES.userProfile) && <PersonIcon />}
                                    {!location.pathname.includes(ROUTES.userProfile) && <Person2OutlinedIcon />}
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ span: { color: theme.palette.secondary.light }, marginLeft: '-18px' }}
                                    primary={t('skeleton.User')}
                                />
                            </ListItem>
                            <Menu
                                id={'settings-menu'}
                                anchorEl={settingsAnchorEl}
                                keepMounted
                                open={Boolean(settingsAnchorEl)}
                                onClose={handleSettingsClose}>
                                <MenuItem onClick={() => setPage(ROUTES.userProfile)}>{t('skeleton.Profile')}</MenuItem>
                                {/* <MenuItem
									aria-controls={'language-menu'}
									aria-haspopup="true"
									onClick={(e: any) => handleLanguageClick(e)}>
									{t('skeleton.Language')}
								</MenuItem> */}
                                <MenuItem
                                    onClick={() => {
                                        logoutUser();
                                    }}>
                                    {t('skeleton.LogOut')}
                                </MenuItem>
                            </Menu>
                            {/* <Menu
								id={'language-menu'}
								anchorEl={languageAnchorEl}
								keepMounted
								open={Boolean(languageAnchorEl)}
								onClose={handleLanguageClose}>
								<MenuItem
									onClick={() => changeLanguage('hr')}
									sx={{
										height: '56px',
										'&.Mui-selected': {
											backgroundColor: theme.palette.secondary.light,
											span: {
												color: theme.palette.primary.light
											},
											'&:hover *': {
												color: theme.palette.secondary.light
											}
										}
									}}
									selected={i18n.language === 'hr'}>
									{t('language.Croatian')}
								</MenuItem>
								<MenuItem
									onClick={() => changeLanguage('en')}
									sx={{
										height: '56px',
										'&.Mui-selected': {
											backgroundColor: theme.palette.secondary.light,
											span: {
												color: theme.palette.primary.light
											},
											'&:hover *': {
												color: theme.palette.secondary.light
											}
										}
									}}
									selected={i18n.language === 'en'}>
									{t('language.English')}
								</MenuItem>
							</Menu> */}

                            <ListItem
                                sx={{
                                    height: '56px',
                                    '&.Mui-selected': {
                                        backgroundColor: theme.palette.secondary.light,
                                        span: {
                                            color: theme.palette.primary.light
                                        },
                                        '&:hover *': {
                                            color: theme.palette.secondary.light
                                        }
                                    }
                                }}
                                button
                                onClick={() => setOpenSideNav(!openSideNav)}>
                                <ListItemIcon>{openSideNav ? <ChevronRightIcon /> : <ChevronLeftIcon />}</ListItemIcon>
                            </ListItem>
                        </List>
                    </div>
                </Grid>
            </DrawerStyled>
            <Grid sx={classes.content}>
                <UserProvider>
                    <MainView>
                        <Outlet />
                    </MainView>
                </UserProvider>
            </Grid>
            <SetPassword open={!!getUserInfo?.password_reset_required}></SetPassword>
        </Grid>
    );
};
