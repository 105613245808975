import React, { useContext, useState } from 'react';
import { Typography, Grid, Chip } from '@mui/material';
import { ForceUserDataDialog, PrivateWrapper } from 'components';
import { useTranslation } from 'react-i18next';
import { PublishOfferDialog } from './PublishOfferDialog';
import { TaskTypes, TaskFilters, taskTypes } from '../../../../parameters/tasks';
import { MarketGrid } from './MarketGrid';
import { UserContext } from 'context';

export const Market = () => {
    const { t } = useTranslation();

    const { forceUserDataInput } = useContext(UserContext);

    const [selectedGroup, setselectedGroup] = useState<TaskTypes>('TRANSPORTATION');

    const [selectedFilter] = useState<TaskFilters>('PUBLISHED');

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [dialogForceUserDataOpen, setDialogForceUserDataOpen] = React.useState(false);

    const handleDialogOpen = () => {
        if (forceUserDataInput) {
            setDialogForceUserDataOpen(true);
            return;
        }
        setDialogOpen(true);
    };

    return (
        <>
            <PrivateWrapper
                title={t('marketPage.PageTitle')}
                buttonTitle={t('marketPage.PublishOfferDialog.DialogTitle')}
                action={handleDialogOpen}
                buttonDisabled={false}>
                <Grid
                    container
                    item={true}
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='start'
                    gap={2}
                    xs={12}
                    p={1}>
                    <Typography variant='h6' gutterBottom>
                        {t('buttons.FilterButtonLabel')}
                    </Typography>
                    <Grid container item={true} direction='row' justifyContent='flex-start' alignItems='start' gap={2}>
                        <Grid xs={12} direction='row' justifyContent='start' alignItems='center' px={1}>
                            {taskTypes.map(el => (
                                <Chip
                                    label={t(el.toUpperCase())}
                                    variant={selectedGroup === el ? 'filled' : 'outlined'}
                                    onClick={() => setselectedGroup(el)}
                                />
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item={true}
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='start'
                    gap={1}
                    xs={12}
                    p={1}>
                    <Typography variant='h6' gutterBottom>
                        {t(selectedGroup)}
                    </Typography>
                    <Grid container item={true} direction='column' sx={{ height: 600, width: '100%' }}>
                        <MarketGrid task_type={selectedGroup} filter={selectedFilter}></MarketGrid>
                    </Grid>
                </Grid>
            </PrivateWrapper>

            <PublishOfferDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
            <ForceUserDataDialog open={dialogForceUserDataOpen} onClose={() => setDialogForceUserDataOpen(false)} />
        </>
    );
};
