import React, { useContext, useRef } from 'react';
import { IMarketTaskFull, ITaskType } from 'models';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AcceptTask } from 'services';
import { DetailsDialog } from 'components/ux/dialog/DetailsDialog';
import MarketForm from './MarketForm';
import useConfirm from 'hooks/useConfirm';
import { ForceUserDataDialog } from '../user/ForceUserDataDialog';
import { GlobalContext, UserContext } from 'context';

export interface NotificationDialogProps {
    open: boolean;
    view: boolean;
    selected?: string;
    urlSingle: string;
    onClose?: () => void;
    onOpen?: () => void;
}

export const MarketOfferDetailsDialog = (props: NotificationDialogProps) => {
    const marketFormRef = useRef<any>(null);
    const { forceUserDataInput } = useContext(UserContext);
    const { onClose, selected, open, onOpen, urlSingle, view } = props;
    const { t } = useTranslation();
    const [ConfirmationDialog, confirmEvent] = useConfirm(
        t('marketOfferDetailsDialog.AcceptOffer'),
        t('marketOfferDetailsDialog.AcceptOfferConfirmation')
    );

    const { taskTypeListQuery } = useContext(GlobalContext);

    const [dialogForceUserDataOpen, setDialogForceUserDataOpen] = React.useState(false);

    const handleClose = () => {
        onClose && onClose();
    };

    const handleAccept = () => {
        if (forceUserDataInput) {
            setDialogForceUserDataOpen(true);
            return;
        }
        marketFormRef.current.triggerSubmit();
    };

    const onSubmit = async (data: IMarketTaskFull) => {
        const ans = await confirmEvent();
        if (ans && selected) {
            AcceptTask({
                id: data?.id,
                task_code: taskTypeListQuery.data
                    ?.filter((el: ITaskType) => Number(el.id) === Number(data?.task_type))
                    .map(el => el.code)[0]
            })
                .then(res => {
                    toast.success(t('notificationDialog.TaskAccepted'));
                    handleClose();
                })
                .catch(err => {
                    toast.error(`${t('Task Accepting Failed')}: ${err.message}`);
                })
                .finally(() => {
                    handleClose();
                });
        }
    };

    return (
        <>
            <DetailsDialog
                open={open}
                onClose={handleClose}
                onOpen={onOpen}
                onAccept={view ? undefined : handleAccept}
                buttonOKtitle={t('buttons.AcceptButtonLabel')}
                title={t('marketOfferDetailsDialog.TaskDetails')}>
                <>
                    <MarketForm
                        ref={marketFormRef}
                        onSubmit={onSubmit}
                        selected={selected}
                        url={urlSingle}
                        preview={true}></MarketForm>
                    <ConfirmationDialog />
                </>
            </DetailsDialog>
            <ForceUserDataDialog open={dialogForceUserDataOpen} onClose={() => setDialogForceUserDataOpen(false)} />
        </>
    );
};
