import React, { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthorizationContext } from 'context';
import { SignInUserCall } from 'services';
import { useCallbackWithLoading } from 'hooks';
import { useForm, Controller } from 'react-hook-form';
import { LoginUser } from 'models';
import { UserManagementWrapper } from '../UserManagementWrapper';
import { CardStyled } from '../UserManagementWrapper.styled';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'parameters';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { BytegaButton, FormStyled, TextFieldStyled } from 'components';

export const Login = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isAuth, isAdmin, setUserData } = useContext(AuthorizationContext);

    const [login, loginPending] = useCallbackWithLoading(SignInUserCall);
    //const [rememberMe, setRememberMe] = useState(false);
    //const toggleRememberMe = useCallback(() => setRememberMe((v) => !v), []);

    const validationSchema = Yup.object().shape({
        email: Yup.string().required(t('Email is required')).email(t('Email is invalid')),
        password: Yup.string().required(t('Password is required'))
    });

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            email: '',
            password: ''
        },
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: LoginUser) => {
        login({ email: data.email, password: data.password })
            .then(res => {
                setUserData(res.currentUser);
                toast.success(t('Login Successful'));
            })
            .catch(err => {
                toast.error(`${t('Login Failed')}: ${err.message}`);
            });
    };

    useEffect(() => {
        if (isAuth) {
            isAdmin ? navigate(ROUTES.admin) : navigate(ROUTES.app);
        }
    }, [isAuth, isAdmin, navigate]);

    return (
        <UserManagementWrapper>
            <FormStyled onSubmit={handleSubmit(onSubmit)}>
                <CardStyled elevation={0}>
                    <Grid container direction='column' justifyContent='flex-start' alignItems='center' gap={1} p={1}>
                        <Controller
                            name='email'
                            control={control}
                            render={({ field }) => (
                                <TextFieldStyled
                                    {...field}
                                    required
                                    id='email'
                                    label={`${t('Email')}`}
                                    error={errors.email ? true : false}
                                    helperText={errors.email?.message ? errors.email?.message : null}
                                />
                            )}
                        />
                        <Controller
                            name='password'
                            control={control}
                            render={({ field }) => (
                                <TextFieldStyled
                                    {...field}
                                    required
                                    id='password'
                                    label={`${t('Password')}`}
                                    type='password'
                                    autoComplete='current-password'
                                    error={errors.password ? true : false}
                                    helperText={errors.password?.message ? errors.password?.message : null}
                                />
                            )}
                        />
                        <BytegaButton
                            className={`primary`}
                            type='submit'
                            variant='contained'
                            disabled={loginPending}
                            text={t('Login')}
                            fullWidth={true}
                        />

                        <BytegaButton
                            variant='outlined'
                            type='reset'
                            disabled={loginPending}
                            onClick={() => navigate(ROUTES.forgotPassword)}
                            text={t('Forgot password')}
                            fullWidth={true}
                        />

                        <BytegaButton
                            variant='outlined'
                            type='reset'
                            disabled={loginPending}
                            onClick={() => navigate(ROUTES.register)}
                            text={t("Don't have account?")}
                            fullWidth={true}
                        />
                    </Grid>
                </CardStyled>
            </FormStyled>
        </UserManagementWrapper>
    );
};
