import { GridContentStyled, GridStyledWrapper } from './UserManagementWrapper.styled';

interface Props {
    children: React.ReactNode;
}

export const UserManagementWrapper = ({ children }: Props) => {
    return (
        <GridStyledWrapper>
            <GridContentStyled container={true} direction='column' justifyContent='flex-start' alignItems='center'>
                {children}
            </GridContentStyled>
        </GridStyledWrapper>
    );
};
