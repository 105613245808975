import { styled } from '@mui/material';
import { sizes } from 'parameters';

export const MainWrapper = styled('main')(({ theme }) => ({
    minHeight: 'calc(100vh - 230px)',
    h1: {
        textAlign: 'center',
        marginTop: '-5%',
        position: 'absolute',
        left: 0,
        right: '25%',
        maxWidth: '40%',
        marginLeft: 'auto',
        marginRight: 'auto',
        color: theme.palette.secondary.light,
        backgroundColor: 'rgba(73 ,133, 121 , 55%)',
        fontSize: '32px'
    },
    h2: {
        textAlign: 'center',
        position: 'absolute',
        marginTop: '15%',
        left: '25%',
        right: 0,
        maxWidth: '40%',
        marginLeft: 'auto',
        marginRight: 'auto',
        color: theme.palette.secondary.light,
        backgroundColor: 'rgba(73 ,133, 121 , 55%)',
        fontSize: '20px'
    },
    [theme.breakpoints.up('sm')]: {
        h1: {
            fontSize: '64px'
        },
        h2: {
            fontSize: '32px'
        }
    }
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
    borderRadius: '8px',
    maxWidth: '906px',
    maxHeight: 'calc(100vh - 230px)',
    overflowY: 'auto',
    margin: '0 auto',
    backgroundColor: theme.palette.secondary.light,
    [theme.breakpoints.up('sm')]: {
        padding: '24px'
    }
}));

export const DateText = styled('p')(({ theme }) => ({
    opacity: '0.6',
    textAlign: 'end',
    fontSize: '12px',
    paddingBottom: '16px',
    paddingRight: '16px',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
        fontSize: '14px,'
    }
}));
