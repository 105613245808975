import React, { forwardRef, useMemo, useImperativeHandle, useRef, useEffect, useContext } from 'react';
import {
    Autocomplete,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    TextField,
    Tooltip
} from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { FormSimpleStyled, SelectProfileStyled, TextFieldProfileStyled } from 'components';
import { ICity, IStorageInput, IStorageViewModel } from 'models';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import { removeNull } from 'utils';
import { GlobalContext } from 'context';
import { UseQueryResult } from 'react-query';

type Props = {
    onSubmit: (data: IStorageInput) => void;
    storageQuery?: UseQueryResult<IStorageViewModel, unknown>;
};

const StorageForm = forwardRef((props: Props, ref) => {
    const formRef = useRef<any>(null);
    const { onSubmit, storageQuery } = props;
    const { t } = useTranslation();
    const { cityListQuery } = useContext(GlobalContext);

    const validationSchema = Yup.object().shape({
        classification: Yup.string().required(t('taskForm.classification_required')),
        address: Yup.string().required(t('taskForm.address_required')),
        city: Yup.number().required(t('taskForm.city_required')),
        dimensions: Yup.string().required(t('taskForm.dimensions_required'))
    });

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            classification: '',
            address: '',
            city: 0,
            dimensions: ''
        },
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        if (!!storageQuery?.data?.id) {
            const cleanedStorage = removeNull(storageQuery?.data as any);
            Object.keys(cleanedStorage).forEach((key: string) => {
                const formKey = `${key}` as any;
                setValue(formKey, cleanedStorage[key], { shouldDirty: false });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storageQuery?.data]);

    const loading = useMemo(() => !cityListQuery.isLoading && !storageQuery?.isLoading, [cityListQuery, storageQuery]);

    useImperativeHandle(ref, () => ({
        triggerSubmit: () => {
            handleSubmit(onSubmit)();
        }
    }));

    return loading ? (
        <FormSimpleStyled ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction='column' justifyContent='flex-start' alignItems='start' gap={1} py={1}>
                <Controller
                    name='classification'
                    control={control}
                    render={({ field }) => (
                        <TextFieldProfileStyled
                            {...field}
                            required
                            id='classification'
                            label={`${t('taskDetails.classification')}`}
                            error={errors?.classification ? true : false}
                            helperText={errors?.classification?.message ? errors?.classification?.message : null}
                            autoComplete='classification'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Tooltip title={t('userStorage.ClassificationInfo')}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
                <Controller
                    name='address'
                    control={control}
                    render={({ field }) => (
                        <TextFieldProfileStyled
                            {...field}
                            required
                            id='address'
                            label={`${t('taskDetails.address')}`}
                            error={errors?.address ? true : false}
                            helperText={errors?.address?.message ? errors?.address?.message : null}
                        />
                    )}
                />
                <Controller
                    name='dimensions'
                    control={control}
                    render={({ field }) => (
                        <TextFieldProfileStyled
                            {...field}
                            required
                            id='dimensions'
                            label={`${t('taskDetails.dimensions')}`}
                            error={errors?.dimensions ? true : false}
                            helperText={errors?.dimensions?.message ? errors?.dimensions?.message : null}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Tooltip title={t('userStorage.DimensionsInfo')}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
                <Controller
                    name='city'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControl fullWidth>
                            <Autocomplete
                                onChange={(event: any, newValue: any | null) => {
                                    onChange(newValue.value);
                                }}
                                options={
                                    cityListQuery.data?.map(el => {
                                        return { label: el.name, value: el.id };
                                    }) || []
                                }
                                value={
                                    value
                                        ? cityListQuery.data
                                              ?.filter(el => el.id === value)
                                              .map(el => {
                                                  return { label: el.name, value: el.id };
                                              })[0]
                                        : null
                                }
                                sx={{ width: 300 }}
                                renderInput={(params: any) => (
                                    <TextField {...params} label={t('taskDetails.city') + '*'} variant='outlined' />
                                )}
                            />
                            <FormHelperText error={true}>
                                {errors.city?.message ? errors.city?.message : ''}
                            </FormHelperText>
                        </FormControl>
                    )}
                />
            </Grid>
        </FormSimpleStyled>
    ) : (
        <CircularProgress color='inherit' />
    );
});

export default StorageForm;
