import { useState } from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import { PrivateWrapper } from 'components';
import { TaskGrid } from './TaskGrid';
import { useTranslation } from 'react-i18next';
import {
    TaskFilters,
    taskFilters,
    taskTypes,
    TaskTypes,
    taskUserState,
    TaskUserState
} from '../../../../parameters/tasks';

export const MyTasks = () => {
    const { t } = useTranslation();

    const [selectedState, setselectedState] = useState<TaskUserState>('SUBMITTER');

    const [selectedGroup, setselectedGroup] = useState<TaskTypes>('TRANSPORTATION');

    const [selectedFilter, setselectedFilter] = useState<TaskFilters>('PUBLISHED');

    return (
        <PrivateWrapper title={t('myTasks.MyTasksLabel')}>
            <Grid
                container
                item={true}
                direction='column'
                justifyContent='flex-start'
                alignItems='start'
                gap={2}
                xs={12}
                p={1}>
                <Typography variant='h6' gutterBottom>
                    {t('buttons.FilterButtonLabel')}
                </Typography>
                <Grid container item={true} direction='row' justifyContent='flex-start' alignItems='start' gap={2}>
                    <Grid xs={12} direction='row' justifyContent='start' alignItems='center' px={1}>
                        {taskUserState.map(el => (
                            <Chip
                                label={t(el).toUpperCase()}
                                variant={selectedState === el ? 'filled' : 'outlined'}
                                onClick={() => setselectedState(el)}
                            />
                        ))}
                    </Grid>
                    <Grid xs={12} direction='row' justifyContent='start' alignItems='center' px={1}>
                        {taskTypes.map(el => (
                            <Chip
                                label={t(el).toUpperCase()}
                                variant={selectedGroup === el ? 'filled' : 'outlined'}
                                onClick={() => setselectedGroup(el)}
                            />
                        ))}
                    </Grid>
                    <Grid xs={12} direction='row' justifyContent='start' alignItems='center' px={1}>
                        {taskFilters.map(el => (
                            <Chip
                                label={t(el).toUpperCase()}
                                variant={selectedFilter === el ? 'filled' : 'outlined'}
                                onClick={() => setselectedFilter(el)}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                item={true}
                direction='column'
                justifyContent='flex-start'
                alignItems='start'
                gap={1}
                xs={12}
                p={1}>
                <Typography variant='h6' gutterBottom>
                    {t(selectedGroup)}
                </Typography>
                <Grid container item={true} direction='column' sx={{ height: 600, width: '100%' }}>
                    <TaskGrid task_state={selectedState} task_type={selectedGroup} filter={selectedFilter}></TaskGrid>
                </Grid>
            </Grid>
        </PrivateWrapper>
    );
};
