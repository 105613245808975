import { sizes } from 'parameters';
import { styled } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    [theme.breakpoints.up('sm')]: {
        padding: '8px 32px'
    }
}));

export const LinkContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '32px',
    a: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        color: theme.palette.secondary.light,
        textTransform: 'uppercase',
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.secondary.dark
        }
    }
}));

export const LogoContainer = styled('div')(({ theme }) => ({
    maxWidth: '65px',
    img: {
        width: '100%',
        height: '65px'
    },
    [theme.breakpoints.up('sm')]: {
        maxWidth: '108px'
    }
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '16px',
    height: '0%'
}));

export const Desktop = styled('div')(({ theme }) => ({
    display: 'inline',
    [theme.breakpoints.up('sm')]: {
        display: 'none'
    }
}));
