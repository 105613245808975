import { HomeServices, PublicWrapper } from 'components';
import { useCallback, useRef } from 'react';
import { loadFull } from 'tsparticles';
import Particles from 'react-particles';
import type { Container, Engine } from 'tsparticles-engine';
import { HomeParicle } from './Home.styled';
import { HomeParameters } from './Home.parameters';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'parameters';
import { useTranslation } from 'react-i18next';

type Props = {};

export function Home(props: Props) {
    let { link } = useParams();
    const navigate = useNavigate();
    const serviceRef = useRef(null) as any;
    const { t } = useTranslation();
    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        if (container) {
            container.canvas.resize();
        }
    }, []);

    const title = t('homePage.title');
    const subtitle = t('homePage.subtitle');

    if (!!link) {
        if (link === 'services' && !!serviceRef) {
            window.scrollTo({
                top: serviceRef?.current?.offsetTop - 81,
                left: 0,
                behavior: 'smooth'
            });
        } else {
            navigate(ROUTES.notdefined);
        }
    }

    return (
        <PublicWrapper>
            <>
                <HomeParicle>
                    <Particles
                        id='tsparticles'
                        init={particlesInit}
                        loaded={particlesLoaded}
                        height='100%'
                        width='100%'
                        options={HomeParameters.particles}
                    />
                    <h1 id='hero_title'>{title}</h1>
                    <h2 id='hero_subtitle'>{subtitle}</h2>
                </HomeParicle>
                <HomeServices serviceRef={serviceRef} />
            </>
        </PublicWrapper>
    );
}
