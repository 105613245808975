import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthorizationContext } from 'context';
import { ActivateUserCall } from 'services';
import { UserManagementWrapper } from '../UserManagementWrapper';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ROUTES, GOOD_REQUEST } from 'parameters';

export const Activate = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isAuth } = useContext(AuthorizationContext);
    let { uid, token } = useParams();

    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('');

    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (isAuth) {
            navigate(ROUTES.home);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (redirect) {
            timeout = setTimeout(() => {
                navigate(ROUTES.home);
            }, 3000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [navigate, redirect]);

    useEffect(() => {
        if (uid && token) {
            ActivateUserCall({ uid, token })
                .then(res => {
                    if (res === GOOD_REQUEST) {
                        toast.success(t('Validation Successfull'));
                        setStatus(t('Thank you for validating.'));
                        setRedirect(true);
                    } else {
                        throw new Error(res);
                    }
                })
                .catch(error => {
                    //return error data
                    console.error(error);
                    toast.error(t('Validation Errored'));
                    setStatus(t('Error occured during validation.'));
                    setRedirect(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (uid && token) {
            toast.info(t('Validating token'));
        } else {
            toast.error(t('Missing UID and Token'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <UserManagementWrapper>{loading ? <>{t('Loading')}</> : <div>{status}</div>}</UserManagementWrapper>;
};
