import React from 'react';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { GlobalRouter } from 'router';

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#1C6758'
        },
        secondary: {
            main: '#EEF2E6'
        },
        error: {
            main: '#B30800'
        },
        success: {
            main: '#52B300'
        },
        info: {
            main: '#6100B3'
        }
    }
});

function App() {
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ToastContainer
                    position='bottom-right'
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover
                    theme='light'
                />
                <GlobalRouter />
            </ThemeProvider>
        </React.Fragment>
    );
}

export default App;
