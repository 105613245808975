import { MainWrapper, ContentWrapper, DateText } from 'components/Main.styled';
import { useTranslation } from 'react-i18next';
import { Section, TextWrapper, Title } from './TermsOfService.styled';

export const TermsAndConditions = () => {
    const { t } = useTranslation();
    return (
        <MainWrapper>
            <TextWrapper>
                <h1>{t('tos.headline')}</h1>
                <ContentWrapper>
                    <DateText>LAST UPDATED: 08.11.2022.</DateText>
                    <Title>{t('tos.section.1.headline')}</Title>
                    <Section>
                        <p>{t('tos.section.1.1')}</p>
                        <p>{t('tos.section.1.2')}</p>
                        <p>{t('tos.section.1.3')}</p>
                        <p>{t('tos.section.1.4')}</p>
                    </Section>
                    <Title>{t('tos.section.2.headline')}</Title>
                    <Section>
                        <p>{t('tos.section.2.1')}</p>
                        <p>{t('tos.section.2.2')}</p>
                        <p>{t('tos.section.2.3')}</p>
                        <p>{t('tos.section.2.4')}</p>
                    </Section>
                    <Title>{t('tos.section.3.headline')}</Title>
                    <Section>
                        <p>{t('tos.section.3.1')}</p>
                        <p>{t('tos.section.3.2')}</p>
                        <p>{t('tos.section.3.3')}</p>
                        <p>{t('tos.section.3.4')}</p>
                        <p>{t('tos.section.3.5')}</p>
                        <p>{t('tos.section.3.6')}</p>
                    </Section>
                    <Title>{t('tos.section.4.headline')}</Title>
                    <Section>
                        <p>{t('tos.section.4.1')}</p>
                        <p>{t('tos.section.4.2')}</p>
                    </Section>
                    <Title>{t('tos.section.5.headline')}</Title>
                    <Section>
                        <p>{t('tos.section.5.1')}</p>
                        <p>{t('tos.section.5.2')}</p>
                    </Section>
                    <Title>{t('tos.section.6.headline')}</Title>
                    <Section>
                        <p>{t('tos.section.6.1')}</p>
                    </Section>
                    <Title>{t('tos.section.7.headline')}</Title>
                    <Section>
                        <p>{t('tos.section.7.1')}</p>
                        <p>{t('tos.section.7.2')}</p>
                        <p>{t('tos.section.7.3')}</p>
                        <p>{t('tos.section.7.4')}</p>
                    </Section>
                </ContentWrapper>
            </TextWrapper>
        </MainWrapper>
    );
};
