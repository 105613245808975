import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
const useConfirm = (title: string, message: string) => {
    const [promise, setPromise] = useState<any>(null);
    const { t } = useTranslation();

    const confirmEvent = (): Promise<boolean> =>
        new Promise((resolve, reject) => {
            setPromise({ resolve });
        });

    const handleClose = () => {
        setPromise(null);
    };

    const handleConfirm = () => {
        promise?.resolve(true);
        handleClose();
    };

    const handleCancel = () => {
        promise?.resolve(false);
        handleClose();
    };
    // You could replace the Dialog with your library's version
    const ConfirmationDialog = () => (
        <Dialog open={promise !== null} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>{t('buttons.OKButtonLabel')}</Button>
                <Button onClick={handleCancel}>{t('buttons.CancelButtonLabel')}</Button>
            </DialogActions>
        </Dialog>
    );
    return [ConfirmationDialog, confirmEvent] as [() => JSX.Element, () => Promise<boolean>];
};

export default useConfirm;
