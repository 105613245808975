import { Button, css, styled } from '@mui/material';

export interface ButtonStyleProps {
    disabled?: boolean;
    fullWidth?: boolean;
    disablemargin?: boolean;
    disablepadding?: boolean;
    iconfirst?: boolean;
}

export const WrapperIcon = styled('div')(
    () => `
position: relative;
left: 1.2rem;
flex-shrink: 0;
display: flex;
`
);

export const ButtonLink = styled('a')(
    () => `
	text-decoration: none;
	max-width: fit-content;
`
);

export const StyledBaseButton = styled(Button)<ButtonStyleProps>(
    ({ disabled, fullWidth, disablemargin, disablepadding, iconfirst, theme }) => `

	${
        iconfirst &&
        css`
            flex-direction: row-reverse;
            gap: 1.2rem;
            ${WrapperIcon} {
                left: 0;
            }
        `
    }

	${
        disablemargin &&
        css`
            margin: 0;
        `
    }


	${
        disabled &&
        css`
            pointer-events: none;
        `
    }

	
	${
        disablepadding &&
        css`
            padding: 0;
        `
    }

	${
        fullWidth &&
        css`
            width: 100%;
        `
    }
`
);

export const StyledButton = styled(StyledBaseButton)<ButtonStyleProps>``;
