import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ForgotPasswordConfirmCall } from 'services';
import { useCallbackWithLoading } from 'hooks';
import { useForm, Controller } from 'react-hook-form';
import { UserManagementWrapper } from '../UserManagementWrapper';
import { CardStyled } from '../UserManagementWrapper.styled';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'parameters';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BytegaButton, FormStyled, TextFieldStyled } from 'components';

export const ForgotPasswordConfirm = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    let { uid, token } = useParams();
    const [forgot, forgotPending] = useCallbackWithLoading(ForgotPasswordConfirmCall);

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required(t('Password is required'))
            .min(6, t('Password must be at least 6 characters'))
            .max(40, t('Password must not exceed 40 characters')),
        re_password: Yup.string()
            .label(t('Confirm Password'))
            .required()
            .oneOf([Yup.ref('password')], t('Passwords must match'))
    });

    const {
        control,
        handleSubmit,

        formState: { errors }
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            password: '',
            re_password: ''
        },
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: { password: string; re_password: string }) => {
        forgot({ new_password: data.password, re_new_password: data.re_password, uid, token })
            .then((res: boolean) => {
                toast.success(t('Change Successful'));
                navigate(ROUTES.login);
            })
            .catch((error: any) => {
                console.error('Forgot Password error', error);
                toast.error(t('Request Failed'));
            });
    };

    return (
        <UserManagementWrapper>
            <FormStyled onSubmit={handleSubmit(onSubmit)}>
                <CardStyled elevation={0}>
                    <Grid container direction='column' justifyContent='flex-start' alignItems='center' gap={1} p={1}>
                        <Controller
                            name='password'
                            control={control}
                            render={({ field }) => (
                                <TextFieldStyled
                                    {...field}
                                    required
                                    id='password'
                                    type='password'
                                    label={`${t('Password')}`}
                                    error={errors.password ? true : false}
                                    helperText={errors.password?.message ? errors.password?.message : null}
                                />
                            )}
                        />
                        <Controller
                            name='re_password'
                            control={control}
                            render={({ field }) => (
                                <TextFieldStyled
                                    {...field}
                                    required
                                    id='re_password'
                                    type='password'
                                    label={`${t('Password Repeat')}`}
                                    error={errors.re_password ? true : false}
                                    helperText={errors.re_password?.message ? errors.re_password?.message : null}
                                />
                            )}
                        />
                        <BytegaButton
                            className={`primary`}
                            type='submit'
                            variant='contained'
                            disabled={forgotPending}
                            text={t('resetpassword')}
                            fullWidth={true}
                        />
                    </Grid>
                </CardStyled>
            </FormStyled>
        </UserManagementWrapper>
    );
};
