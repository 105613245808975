import React, { useEffect, useMemo, useContext } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { GetAdminAllUsers } from 'services';
import { useQuery } from 'react-query';
import { DrawerSideWindow } from 'components';
import { UserContext } from 'context';
import { AdminUserEdit } from './AdminUserEdit';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export const AllUsersGrid = () => {
    const [openDetails, setDetails] = React.useState(false);
    const [selectedRowID, setSelectedRow] = React.useState<number>();
    const { t } = useTranslation();
    const { setSelectedUserId } = useContext(UserContext);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'email', headerName: t('usersAdminGrid.email'), width: 130 },
        {
            field: 'last_login',
            headerName: t('usersAdminGrid.last_login'),
            width: 130,
            renderCell: (params: any) => {
                return params.formattedValue ? dayjs(params.formattedValue).format('DD/MM/YYYY') : '';
            }
        },
        {
            field: 'is_active',
            headerName: t('usersAdminGrid.is_active'),
            width: 80,
            renderCell: (params: any) => {
                return params.formattedValue ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />;
            }
        },

        {
            field: 'created_by_superuser',
            headerName: t('usersAdminGrid.created_by_superuser'),
            width: 80,
            renderCell: (params: any) => {
                return params.formattedValue ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />;
            }
        },

        {
            field: 'is_blocked',
            headerName: t('usersAdminGrid.is_blocked'),
            width: 80,
            renderCell: (params: any) => {
                return params.formattedValue ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />;
            }
        },
        {
            field: 'is_deleted',
            headerName: t('usersAdminGrid.is_deleted'),
            width: 80,
            renderCell: (params: any) => {
                return params.formattedValue ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />;
            }
        },
        {
            field: 'created_at',
            headerName: t('usersAdminGrid.created_at'),
            width: 100,
            renderCell: (params: any) => {
                return params.formattedValue ? dayjs(params.formattedValue).format('DD/MM/YYYY') : '';
            }
        },
        {
            field: 'updated_at',
            headerName: t('usersAdminGrid.updated_at'),
            width: 100,
            renderCell: (params: any) => {
                return params.formattedValue ? dayjs(params.formattedValue).format('DD/MM/YYYY') : '';
            }
        },
        {
            field: '',
            sortable: false,
            headerName: t('usersAdminGrid.Action'),
            filterable: false,
            width: 150,
            renderCell: (params: any) => {
                const onClick = () => {
                    let selectedID: number = params.id;
                    setSelectedRow(selectedID);
                };

                let isDeleted: boolean = params.row.is_deleted;

                return !isDeleted && <Button onClick={onClick}>{t('usersAdminGrid.ActionButton')}</Button>;
            }
        }
    ];

    useEffect(() => {
        if (selectedRowID) {
            setDetails(true);
            setSelectedUserId(selectedRowID);
        }
        return () => {
            setSelectedUserId(Infinity);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowID]);

    const handleClose = () => {
        setDetails(false);
        setSelectedRow(undefined);
    };

    // TODO
    const usersQuery = useQuery(['admin_users'], () => GetAdminAllUsers(), {
        staleTime: 10000
    });

    const loading = useMemo(() => !usersQuery.isLoading, [usersQuery]);

    return (
        <>
            {loading ? (
                <DataGrid rows={usersQuery.data || ([] as any)} columns={columns} pageSize={10} />
            ) : (
                <CircularProgress color='inherit' />
            )}
            <DrawerSideWindow title='userAdminEdit.title' open={openDetails} onClose={handleClose}>
                <AdminUserEdit></AdminUserEdit>
            </DrawerSideWindow>
        </>
    );
};
