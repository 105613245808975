export const ROUTES = {
    // public
    home: '/',
    services: 'services',
    login: '/login',
    register: '/register',
    createAccount: '/create-account',
    activateAccount: '/activate/:uid/:token',
    forgotPassword: '/forgot-password',
    checkInbox: '/check-inbox',
    createNewPassword: '/create-new-password',
    resetPasswordConfirm: '/password/reset/confirm/:uid/:token',
    confirmEmailChange: '/confirm-email-change',
    emailChangeConfirmed: '/email-change-confirmed/:uid',
    contact: '/contact',
    privacy: '/privacy-policy',
    terms: '/terms-and-conditions',
    setPassword: '/set-password',

    // secured rutes
    app: '/app',
    admin: '/admin',

    // nested
    market: 'market',
    vehicle: 'vehicle',
    storage: 'storage',
    mytasks: 'my-tasks',
    userProfile: 'user-profile',
    users: 'users',

    //utility
    forbidden: '/403',
    session: '/202',
    serverError: '/500',
    notdefined: '/404',
    nomatch: '*'
};
