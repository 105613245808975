import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Wrapper, LogoContainer, ButtonContainer, Desktop, LinkContainer } from './Navbar.styled';
import logoImg from '../../assets/logo_transparent_white.png';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'parameters';
import { AuthorizationContext } from 'context';
import { BytegaButton } from 'components';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem } from '@mui/material';
import languageIcon from '../../assets/language.svg';
import { IconsContainer } from 'components/footer/Footer.styled';

type Props = {};

export function Navbar(props: Props) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { isAuth, logoutUser } = useContext(AuthorizationContext);

    const [languageAnchorEl, setLanguageAnchorEl] = useState<null | HTMLElement>(null);

    const handleLanguageClick: any = (event: React.MouseEvent<HTMLElement>) => {
        setLanguageAnchorEl(event.currentTarget);
    };

    const handleLanguageClose = () => {
        setLanguageAnchorEl(null);
    };

    const changeLanguage = (lng: string) => {
        localStorage.setItem('i18nextLng', lng);
        i18n.changeLanguage(lng);
        handleLanguageClose();
    };

    const open = Boolean(languageAnchorEl);

    return (
        <Wrapper>
            <LinkContainer>
                <LogoContainer>
                    <Link to='/'>
                        <img src={logoImg} alt='logo' />
                    </Link>
                </LogoContainer>
                <Link to='/services'>
                    <span>{t('Services')}</span>
                </Link>
            </LinkContainer>

            <ButtonContainer>
                {isAuth ? (
                    <>
                        <BytegaButton
                            variant='contained'
                            size='small'
                            onClick={() => navigate(ROUTES.app)}
                            text={t('Dashboard')}
                        />

                        <BytegaButton
                            variant='outlined'
                            onClick={() => {
                                logoutUser();
                            }}
                            text={t('Log out')}
                        />
                    </>
                ) : (
                    <>
                        <BytegaButton
                            variant='contained'
                            onClick={() => navigate(ROUTES.register)}
                            text={
                                <>
                                    {t('Sign up')}
                                    <Desktop>&nbsp;{t('for free')}</Desktop>
                                </>
                            }
                        />
                        <BytegaButton variant='contained' onClick={() => navigate(ROUTES.login)} text={t('Log in')} />
                    </>
                )}
                <Button
                    aria-controls={open ? 'language-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    variant='text'
                    onClick={handleLanguageClick}>
                    <IconsContainer>
                        <img src={languageIcon} alt='language icon' />
                    </IconsContainer>
                </Button>
                <Menu
                    id={'language-menu'}
                    anchorEl={languageAnchorEl}
                    keepMounted
                    open={Boolean(languageAnchorEl)}
                    onClose={handleLanguageClose}>
                    <MenuItem onClick={() => changeLanguage('hr')} selected={i18n.language === 'hr'}>
                        {t('language.Croatian')}
                    </MenuItem>
                    <MenuItem
                        onClick={() => changeLanguage('en')}
                        sx={{
                            height: '56px'
                        }}
                        selected={i18n.language === 'en'}>
                        {t('language.English')}
                    </MenuItem>
                </Menu>
            </ButtonContainer>
        </Wrapper>
    );
}
