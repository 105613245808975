import React, { useContext, useMemo } from 'react';
import { Button, Chip, CircularProgress, Grid } from '@mui/material';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { GetTasks } from 'services';
import { GetColor } from 'utils';
import { AuthorizationContext, GlobalContext } from 'context';
import { GET_TASKS, TaskFilters, TaskTypes, TaskUserState } from 'parameters';
import { MarketOfferDetailsDialog } from './MarketOfferDetailsDialog';

interface IStorageMarketGrid {
    task_type: TaskTypes;
    filter: TaskFilters;
    task_state?: TaskUserState;
}

export const MarketGrid = ({ task_type, filter, task_state }: IStorageMarketGrid) => {
    const { t } = useTranslation();
    const { getUserProfile } = useContext(AuthorizationContext);
    const [openDetailsDialog, setDetailsDialog] = React.useState(false);
    const [selectedRowID, setSelectedRow] = React.useState('');
    const [selectedStatus, setSelectedStatus] = React.useState(0);
    const { taskTypeListQuery, taskStatusListQuery, cityListQuery } = useContext(GlobalContext);

    const userId = useMemo(() => {
        return getUserProfile?.id ?? null;
    }, [getUserProfile]);

    const url = GET_TASKS({ task_type, task_state, filter, userId });

    const singleUrl = GET_TASKS({ isSingle: true, task_type, task_state, filter, userId, selectedRowID });

    const tasksQuery = useQuery(['tasks', task_type, task_state, filter, userId], () => GetTasks({ url: url }), {
        staleTime: 10000,
        enabled: !!url
    });

    const columns: GridColDef[] = useMemo(() => {
        const taskStorage: any = [
            {
                field: 'storageClass',
                headerName: t('marketGrid.StorageClass'),
                width: 130,

                renderCell: (params: any) => {
                    let selectedStorage = params.row.storage;
                    return `${selectedStorage.classification}`;
                }
            },
            {
                field: 'storageSize',
                headerName: t('marketGrid.StorageSize'),
                width: 130,
                renderCell: (params: any) => {
                    let selectedStorage = params.row.storage;
                    return `${selectedStorage.dimensions}`;
                }
            },
            {
                field: 'city',
                headerName: t('marketGrid.City'),
                width: 130,
                renderCell: (params: any) => {
                    let selectedStorage = params.row.storage;
                    let cityID = selectedStorage.city;
                    return cityListQuery.data?.filter(el => Number(el.id) === Number(cityID)).map(el => el.name);
                }
            }
        ];

        const taskTransport: any = [
            {
                field: 'date_from',
                headerName: t('marketGrid.PickupDate'),
                type: 'date',
                width: 200
            },
            {
                field: 'vehicle',
                headerName: t('marketGrid.Vehicle'),
                width: 130,
                renderCell: (params: any) => {
                    let selectedVehicle = params.row.vehicle;
                    return `${selectedVehicle.vehicle_model} - ${selectedVehicle.vin}`;
                }
            },
            {
                field: 'location_from',
                headerName: t('marketGrid.City'),
                width: 130,
                renderCell: (params: any) => {
                    let selectedID: number = params.row.location_from;
                    return cityListQuery.data?.filter(el => Number(el.id) === Number(selectedID)).map(el => el.name);
                }
            }
        ];

        const taskShipping = [
            {
                field: 'location_from',
                headerName: t('marketGrid.From'),
                width: 130,
                renderCell: (params: any) => {
                    let selectedID: number = params.row.location_from;
                    return cityListQuery.data?.filter(el => Number(el.id) === Number(selectedID)).map(el => el.name);
                }
            },
            {
                field: 'location_to',
                headerName: t('marketGrid.To'),
                width: 130,
                renderCell: (params: any) => {
                    let selectedID: number = params.row.location_to;
                    return cityListQuery.data?.filter(el => Number(el.id) === Number(selectedID)).map(el => el.name);
                }
            },
            {
                field: 'date_from',
                headerName: t('marketGrid.PickupDate'),
                type: 'date',
                width: 200
            },
            {
                field: 'date_to',
                headerName: t('marketGrid.DeliveryDate'),
                type: 'date',
                width: 200
            }
        ];

        const taskBase = [
            { field: 'id', headerName: 'ID', width: 0, hide: true },
            {
                field: 'task_type',
                headerName: t('marketGrid.TaskType'),
                width: 130,
                renderCell: (params: any) => {
                    let selectedID: number = params.row.task_type;
                    console.log(
                        taskTypeListQuery.data?.filter(el => Number(el.id) === Number(selectedID)).map(el => el.name)[0]
                    );
                    return (
                        <Chip
                            label={t(
                                taskTypeListQuery.data
                                    ?.filter(el => Number(el.id) === Number(selectedID))
                                    .map(el => el.name)[0]
                                    .toUpperCase() || ''
                            )}
                            size='small'
                            sx={{
                                color: '#FFFFFF',
                                backgroundColor: GetColor(selectedID),
                                minWidth: '100px',
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                fontSize: '8px'
                            }}
                        />
                    );
                }
            },
            {
                field: 'task_status',
                headerName: t('marketGrid.TaskStatus'),
                width: 130,
                renderCell: (params: any) => {
                    let selectedID: number = params.row.task_status;
                    return (
                        <Chip
                            label={t(
                                taskStatusListQuery.data
                                    ?.filter(el => Number(el.id) === Number(selectedID))
                                    .map(el => el.name)[0]
                                    .toUpperCase() || ''
                            )}
                            size='small'
                            sx={{
                                color: '#FFFFFF',
                                backgroundColor: GetColor(selectedID),
                                minWidth: '100px',
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                fontSize: '8px'
                            }}
                        />
                    );
                }
            },
            {
                field: '',
                headerName: t('marketGrid.Action'),
                sortable: false,
                filterable: false,
                width: 150,
                renderCell: (params: any) => {
                    const onClick = () => {
                        let selectedID: string = params.row.id;
                        let selectedStatus: number = params.row.task_status;
                        console.log('selectedID', selectedID, selectedStatus);
                        setSelectedRow(selectedID);
                        setSelectedStatus(selectedStatus);
                        setDetailsDialog(true);
                    };

                    return <Button onClick={onClick}>{t('myTasks.GridEditDetailsButton')}</Button>;
                }
            }
        ];
        switch (task_type) {
            case 'SHIPPING':
                return [...taskShipping, ...taskBase];
            case 'TRANSPORTATION':
                return [...taskTransport, ...taskBase];
            case 'STORAGE':
                return [...taskStorage, ...taskBase];
            default:
                return taskBase;
        }
    }, [cityListQuery.data, t, taskStatusListQuery.data, taskTypeListQuery.data, task_type]);

    const loading = useMemo(
        () => !tasksQuery.isLoading || !cityListQuery.isLoading,
        [cityListQuery.isLoading, tasksQuery.isLoading]
    );

    return (
        <Grid>
            <div style={{ height: 600, width: '100%' }}>
                {loading ? (
                    <DataGrid rows={tasksQuery.data || []} columns={columns} pageSize={10} />
                ) : (
                    <CircularProgress color='inherit' />
                )}
            </div>

            <MarketOfferDetailsDialog
                selected={selectedRowID}
                open={openDetailsDialog}
                urlSingle={singleUrl}
                view={selectedStatus !== 1 ? true : false}
                onClose={() => setDetailsDialog(false)}
            />
        </Grid>
    );
};
