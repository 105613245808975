import { Card, CardProps, css, Grid, GridProps, styled } from '@mui/material';

interface StyledGridProps extends GridProps {
    success?: boolean;
}

export const GridStyledWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'success'
})<StyledGridProps>(({ success, theme }) => ({
    height: '100vh',
    width: '100vw',
    padding: '150px 48px',
    backgroundColor: theme.palette.primary.light,
    border: 0,
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    '&:before': {
        content: "''",
        height: '100vw',
        width: '200%',
        position: 'absolute',
        marginTop: '-60%',
        marginLeft: '-50%',
        backgroundColor: theme.palette.secondary.light,
        borderRadius: '50%',
        backgroundPosition: 'bottom',
        backgroundImage: "url('frontPageImages/night-lights-road.jpg')",
        backgroundSize: 'contain',
        opacity: 0.9,
        zIndex: -1
    },
    ...(success && css``)
}));

export const GridContentStyled = styled(Grid, {
    shouldForwardProp: prop => prop !== 'success'
})<StyledGridProps>(({ theme }) => ({
    height: '100%',
    width: '100%',
    zIndex: 3,
    '& #hero_title': {
        color: theme.palette.secondary.light,
        backgroundColor: 'rgba(73 ,133, 121 , 55%)'
    }
}));

export const CardStyled = styled(Card)<CardProps>(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main
}));
