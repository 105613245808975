import { UserManagementWrapper } from 'components/user-management/UserManagementWrapper';
import { ROUTES } from 'parameters';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function NotDefined() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate(ROUTES.home);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const title = t('404NotFound');

    return (
        <UserManagementWrapper>
            <h1 id='hero_title'>{title}</h1>
        </UserManagementWrapper>
    );
}
