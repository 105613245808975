import { Link } from 'react-router-dom';
import {
    ContactContainer,
    FooterContainer,
    IconsContainer,
    LegalContainer,
    QuestionsContainer,
    TextContainer,
    TextItem
} from './Footer.styled';
import facebookIcon from '../../assets/facebook.svg';
import { useTranslation } from 'react-i18next';

export function Footer() {
    const { t } = useTranslation();
    return (
        <FooterContainer>
            <ContactContainer>
                <TextContainer>
                    <TextItem order={2}>
                        <h2>{t('Questions?')}</h2>
                        <QuestionsContainer>
                            <p>{t('If you have any questions please contact us at:')}</p>
                            <a href='mailto: info@bytegra-apps.com' type='email'>
                                info@bytegra-apps.com
                            </a>
                        </QuestionsContainer>
                    </TextItem>
                    <TextItem order={3}>
                        <h2>{t('Find us:')}</h2>
                        <IconsContainer>
                            <a
                                href='https://www.facebook.com/profile.php?id=100069455224877'
                                target='_blank'
                                rel='noreferrer'>
                                <img src={facebookIcon} alt='facebook' />
                            </a>
                        </IconsContainer>
                    </TextItem>
                </TextContainer>
            </ContactContainer>
            <LegalContainer>
                <p>Copyright ©2022 Bytegra</p>
                <p>
                    <Link to='/terms-and-conditions'>{t('Terms and Conditions')}</Link> {t('and')}{' '}
                    <Link to='/privacy-policy'>{t('Privacy Policy')}</Link>
                </p>
            </LegalContainer>
        </FooterContainer>
    );
}
