import { IVehicleViewModel, IVehicleGrid, IVehicleInput, IStorageViewModel } from 'models';
import { VEHICLE_GET_ADMIN_VEHICLES, VEHICLE_GET_ADMIN_VEHICLE_CREATE } from 'parameters';
import privateAxios from 'services/global/privateAxios.service';

export const GetAdminVehicles = (selectedUserId?: number): Promise<Array<IVehicleGrid>> => {
    const url =
        VEHICLE_GET_ADMIN_VEHICLES +
        (selectedUserId && selectedUserId !== Infinity ? '?user_account_id=' + selectedUserId : '');
    let taskList = privateAxios
        .get(url)
        .then(res => {
            let responseData: Array<IVehicleViewModel> = res.data;
            let gridData: Array<IVehicleGrid> = [];
            if (responseData !== null && responseData !== undefined) {
                for (let index = 0; index < responseData.length; index++) {
                    const vehicleItem = responseData[index] as IVehicleViewModel;
                    gridData.push({
                        id: vehicleItem.id,
                        vin: vehicleItem.vin ?? '',
                        user: vehicleItem.user ?? null,
                        vehicle_category: vehicleItem.vehicle_category ?? '',
                        vehicle_model: vehicleItem.vehicle_model ?? '',
                        vehicle_brand: vehicleItem.vehicle_brand ?? ''
                    });
                }
            }
            return gridData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to fetch user');
        });

    return taskList;
};

export const GetAdminSingleVehicle = (selectedVehicleId: number): Promise<IVehicleViewModel> => {
    const url = `${VEHICLE_GET_ADMIN_VEHICLES}${selectedVehicleId}/`;
    let singleVehicle = privateAxios
        .get(url)
        .then(res => {
            if (res?.data !== undefined) {
                let responseData: IVehicleViewModel = res.data;
                return responseData;
            } else {
                throw new Error('no data');
            }
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to fetch user');
        });

    return singleVehicle;
};

export const PostNewAdminVehicle = ({
    data,
    selectedUserId
}: {
    data: IVehicleInput;
    selectedUserId: number;
}): Promise<IVehicleViewModel> => {
    let vehicle = privateAxios
        .post(`${VEHICLE_GET_ADMIN_VEHICLE_CREATE}?user_account_id=${selectedUserId}`, data)
        .then(res => {
            let responseData: IVehicleViewModel = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to add vehicle');
        });

    return vehicle;
};

export const PatchAdminVehicle = ({
    data,
    selectedVehicleId
}: {
    data: IVehicleInput;
    selectedVehicleId: number;
}): Promise<IVehicleViewModel> => {
    let vehicle = privateAxios
        .put(`${VEHICLE_GET_ADMIN_VEHICLES}${selectedVehicleId}/`, data)
        .then(res => {
            let responseData: IVehicleViewModel = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to add vehicle');
        });

    return vehicle;
};

export const DeleteAdminVehicle = ({
    selectedVehicleId
}: {
    selectedVehicleId?: number;
}): Promise<IVehicleViewModel> => {
    let vehicle = privateAxios
        .delete(`${VEHICLE_GET_ADMIN_VEHICLES}${selectedVehicleId}/`)
        .then(res => {
            let responseData: IVehicleViewModel = res.data;
            return responseData;
        })
        .catch((error: any) => {
            console.log('error:' + error.data);
            throw new Error('failed to delete vehicle');
        });

    return vehicle;
};
