import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HomeServiceCard } from 'components';

type Props = {
    serviceRef: any;
};

export function HomeServices(props: Props) {
    const { serviceRef } = props;
    const { t } = useTranslation();

    return (
        <div ref={serviceRef}>
            <Grid container direction='column' alignItems='center' py={4}>
                <Grid item py={2}>
                    <Typography gutterBottom variant='h2' component='div'>
                        {t('homePageService.PageTitle')}
                    </Typography>
                </Grid>

                <Grid item container direction='row' justifyContent='center' alignItems='center' py={2} spacing={8}>
                    <Grid item>
                        <HomeServiceCard
                            title={t('Cargo')}
                            imageUrl={'images/home-cargo.jpg'}
                            imageTitle={t('homePageService.Cargo')}
                            description={t('homePageService.CargoDesc')}
                        />
                    </Grid>
                    <Grid item>
                        <HomeServiceCard
                            title={t('Transport')}
                            imageUrl={'images/home-transport.jpg'}
                            imageTitle={t('homePageService.Transport')}
                            description={t('homePageService.TransportDesc')}
                        />
                    </Grid>
                    <Grid item>
                        <HomeServiceCard
                            title={t('Storage')}
                            imageUrl={'images/home-storage.jpg'}
                            imageTitle={t('homePageService.Storage')}
                            description={t('homePageService.StorageDesc')}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
