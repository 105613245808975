import styled from '@emotion/styled';

export const HomeParicle = styled(`div`)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    height: '80vh',
    width: '100%'
}));
