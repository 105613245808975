import React, { useContext, useMemo } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { GetAdminVehicles } from 'services';
import { useQuery } from 'react-query';
import { UserContext } from 'context';
import { VehicleAdminEditDialog } from './VehicleAdminEditDialog';

export const AllAdminVehicleGrid = () => {
    const [openDetailsDialog, setDetailsDialog] = React.useState(false);
    const [selectedRowID, setSelectedRow] = React.useState<number>(Infinity);
    const { t } = useTranslation();

    const { selectedUserId } = useContext(UserContext);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 50 },
        {
            field: 'user',
            headerName: t('marketGrid.User'),
            width: 130,
            renderCell: (params: any) => {
                let selectedUser = params.row.user;
                return selectedUser
                    ? `${selectedUser?.name || t('marketGrid.NoName')} - ${selectedUser?.id}`
                    : t('marketGrid.NoUser');
            }
        },
        { field: 'vehicle_category', headerName: t('userVehicles.VehicleCategoryLabel'), width: 130 },
        { field: 'vehicle_brand', headerName: t('userVehicles.VehicleBrandLabel'), width: 130 },
        { field: 'vehicle_model', headerName: t('userVehicles.VehicleModelLabel'), width: 130 },
        { field: 'vin', headerName: t('userVehicles.VinLabel'), width: 130 },
        {
            field: '',
            sortable: false,
            headerName: t('marketGrid.Action'),
            filterable: false,
            width: 150,
            renderCell: (params: any) => {
                const onClick = () => {
                    setDetailsDialog(true);
                    let selectedID: number = params.row.id;
                    setSelectedRow(selectedID);
                };

                return <Button onClick={onClick}>{t('grid.GridButtons.ActionButton')}</Button>;
            }
        }
    ];

    const handleClose = () => {
        setDetailsDialog(false);
    };

    const vehiclesQuery = useQuery(['admin_vehicles', selectedUserId], () => GetAdminVehicles(selectedUserId), {
        staleTime: 10000
    });

    const loading = useMemo(() => !vehiclesQuery.isLoading, [vehiclesQuery]);

    return (
        <>
            {loading ? (
                <DataGrid rows={vehiclesQuery.data || []} columns={columns} pageSize={10} />
            ) : (
                <CircularProgress color='inherit' />
            )}

            <VehicleAdminEditDialog selected={selectedRowID} open={openDetailsDialog} onClose={handleClose} />
        </>
    );
};
