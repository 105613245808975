import { Footer, Navbar } from 'components';
import { MainWrapper } from 'components/Main.styled';

type Props = {
    children: JSX.Element;
};

export function PublicWrapper(props: Props) {
    const { children } = props;
    return (
        <>
            <Navbar />
            <MainWrapper>{children}</MainWrapper>
            <Footer />
        </>
    );
}
