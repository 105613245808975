import React, { useEffect } from 'react';
import Slide from '@mui/material/Slide';
import { Dialog, DialogTitle, DialogContent, Container, DialogActions, Button } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export interface DetailsDialogProps {
    open: boolean;
    children: React.ReactElement<any, any>;
    title?: string;
    buttonOKtitle?: string;
    onClose?: () => void;
    onOpen?: () => void;
    onAccept?: () => void;
}

export const DetailsDialog = (props: DetailsDialogProps) => {
    const { onClose, open, onOpen, onAccept, children, title, buttonOKtitle } = props;

    const { t } = useTranslation();

    const handleClose = () => {
        onClose && onClose();
    };

    const handleAccept = () => {
        onAccept && onAccept();
    };

    useEffect(() => {
        onOpen && onOpen();
    }, [onOpen]);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
                <DialogTitle id='login-dialog-title'>{t(title ?? 'Details')}</DialogTitle>
                <DialogContent>
                    <Container>{children}</Container>
                </DialogContent>
                <DialogActions>
                    {onClose && (
                        <Button variant='outlined' onClick={handleClose} color='primary'>
                            {t('buttons.CloseButtonLabel')}
                        </Button>
                    )}
                    {onAccept && (
                        <Button variant='outlined' onClick={handleAccept} color='primary'>
                            {t(`${buttonOKtitle ?? 'buttons.OKButtonLabel'}`)}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};
