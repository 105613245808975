import React, { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { Grid, InputAdornment, Tooltip } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { FormSimpleStyled, TextFieldProfileStyled } from 'components';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';
import InfoIcon from '@mui/icons-material/Info';
import { removeNull } from 'utils';
import { IVehicleViewModel } from 'models';

type Props = {
    onSubmit: (data: any) => void;
    vehicleQuery?: UseQueryResult<IVehicleViewModel, unknown>;
};

const VehicleForm = forwardRef((props: Props, ref) => {
    const formRef = useRef<any>(null);
    const { onSubmit, vehicleQuery } = props;
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        vin: Yup.string().required(t('userVehicle.vin_required')),
        dimensions: Yup.string().required(t('"userVehicle.dimensions_required')),
        transport_capacity_kg: Yup.string().required(t('userVehicle.kilogram_capacity_required')),
        transport_capacity_l: Yup.string().required(t('userVehicle.liter_capacity_required')),
        fuel_type: Yup.string().required(t('userVehicle.fuel_type_required')),
        vehicle_category: Yup.string().required(t('userVehicle.category_required')),
        vehicle_model: Yup.string().required(t('userVehicle.model_required')),
        vehicle_brand: Yup.string().required(t('userVehicle.brand_required'))
    });

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            vin: '',
            dimensions: '',
            transport_capacity_kg: undefined,
            transport_capacity_l: undefined,
            fuel_type: '',
            vehicle_category: '',
            vehicle_model: '',
            vehicle_brand: ''
        },
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        if (!!vehicleQuery?.data?.id) {
            const cleanedVehicle = removeNull(vehicleQuery.data as any);
            Object.keys(cleanedVehicle).forEach((key: string) => {
                const formKey = `${key}` as any;
                setValue(formKey, cleanedVehicle[key], { shouldDirty: false });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleQuery?.data]);

    const onError = (errors: any) => {
        console.error('errors', errors);
    };

    useImperativeHandle(ref, () => ({
        triggerSubmit: () => {
            handleSubmit(onSubmit, onError)();
        }
    }));

    return (
        <FormSimpleStyled ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction='column' justifyContent='flex-start' alignItems='start' gap={1} py={1}>
                <Controller
                    name='vin'
                    control={control}
                    render={({ field }) => (
                        <TextFieldProfileStyled
                            {...field}
                            required
                            id='vin'
                            label={`${t('userVehicles.VinLabel')}`}
                            error={errors?.vin ? true : false}
                            helperText={errors?.vin?.message ? errors?.vin?.message : null}
                            autoComplete='vin'
                        />
                    )}
                />
                <Controller
                    name='vehicle_category'
                    control={control}
                    render={({ field }) => (
                        <TextFieldProfileStyled
                            {...field}
                            required
                            id='vehicle_category'
                            label={`${t('userVehicles.VehicleCategoryLabel')}`}
                            error={errors?.vehicle_category ? true : false}
                            helperText={errors?.vehicle_category?.message ? errors?.vehicle_category?.message : null}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Tooltip title={t('userVehicles.CategoryInfo')}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
                <Controller
                    name='vehicle_brand'
                    control={control}
                    render={({ field }) => (
                        <TextFieldProfileStyled
                            {...field}
                            required
                            id='vehicle_brand'
                            label={`${t('userVehicles.VehicleBrandLabel')}`}
                            error={errors?.vehicle_brand ? true : false}
                            helperText={errors?.vehicle_brand?.message ? errors?.vehicle_brand?.message : null}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Tooltip title={t('userVehicles.BrandInfo')}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
                <Controller
                    name='vehicle_model'
                    control={control}
                    render={({ field }) => (
                        <TextFieldProfileStyled
                            {...field}
                            required
                            id='vehicle_model'
                            label={`${t('userVehicles.VehicleModelLabel')}`}
                            error={errors?.vehicle_model ? true : false}
                            helperText={errors?.vehicle_model?.message ? errors?.vehicle_model?.message : null}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Tooltip title={t('userVehicles.ModelInfo')}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
                <Controller
                    name='fuel_type'
                    control={control}
                    render={({ field }) => (
                        <TextFieldProfileStyled
                            {...field}
                            required
                            id='fuel_type'
                            label={`${t('userVehicles.FuelTypeLabel')}`}
                            error={errors?.fuel_type ? true : false}
                            helperText={errors?.fuel_type?.message ? errors?.fuel_type?.message : null}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Tooltip title={t('userVehicles.FuelInfo')}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
                <Controller
                    name='dimensions'
                    control={control}
                    render={({ field }) => (
                        <TextFieldProfileStyled
                            {...field}
                            required
                            id='dimensions'
                            label={`${t('userVehicles.DimensionsLabel')}`}
                            error={errors?.dimensions ? true : false}
                            helperText={errors?.dimensions?.message ? errors?.dimensions?.message : null}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Tooltip title={t('userVehicles.DimensionsInfo')}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
                <Controller
                    name='transport_capacity_kg'
                    control={control}
                    render={({ field }) => (
                        <TextFieldProfileStyled
                            {...field}
                            required
                            id='transport_capacity_kg'
                            label={`${t('userVehicles.CapacityKgLabel')}`}
                            error={errors?.transport_capacity_kg ? true : false}
                            helperText={
                                errors?.transport_capacity_kg?.message ? errors?.transport_capacity_kg?.message : null
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Tooltip title={t('userVehicles.CapacityKilogram')}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
                <Controller
                    name='transport_capacity_l'
                    control={control}
                    render={({ field }) => (
                        <TextFieldProfileStyled
                            {...field}
                            required
                            id='transport_capacity_l'
                            label={`${t('userVehicles.CapacityLabel')}`}
                            error={errors?.transport_capacity_l ? true : false}
                            helperText={
                                errors?.transport_capacity_l?.message ? errors?.transport_capacity_l?.message : null
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Tooltip title={t('userVehicles.CapacityLiter')}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
            </Grid>
        </FormSimpleStyled>
    );
});

export default VehicleForm;
