import { useTranslation } from 'react-i18next';
import { DetailsDialog } from 'components';
import { DialogContent, DialogContentText } from '@mui/material';
import { ROUTES } from 'parameters';
import { useNavigate } from 'react-router-dom';
export interface NotificationDialogProps {
    open: boolean;
    onClose?: () => void;
    onOpen?: () => void;
}

export const ForceUserDataDialog = (props: NotificationDialogProps) => {
    const { onClose, open, onOpen } = props;
    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleClose = () => {
        onClose && onClose();
    };

    const handleAccept = () => {
        navigate(ROUTES.userProfile);
    };

    return (
        <DetailsDialog
            open={open}
            onClose={onClose && handleClose}
            onOpen={onOpen}
            onAccept={handleAccept}
            buttonOKtitle={t('buttons.OKButtonLabel')}
            title={t('marketPage.ForceUserDataDialog.DialogTitle')}>
            <>
                <DialogContent>
                    <DialogContentText>{t('marketPage.ForceUserDataDialog.Message')}</DialogContentText>
                </DialogContent>
            </>
        </DetailsDialog>
    );
};
